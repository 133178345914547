import instance from '../../instance';
import settings from '../../settings';
import clients from './clients';

export default {
  namespaced: true,

  state: {
    list: [],
    tags: [],
    meta: null,
    tagsMeta: null,
    filters: {
      page: 1,
      page_size: 15,
      query: '',
      tags: [],
    },
    item: null,
    loading: {
      list: true,
      item: true,
    },
    tagsFilters: {
      page: 1,
      page_size: 15,
      query: '',
    }
  },

  getters: {
    list: state => state.list,
    tags: state => state.tags,
    meta: state => state.meta,
    tagsMeta: state => state.tagsMeta,
    filters: state => state.filters,
    tagsFilters: state => state.tagsFilters,
    item: state => state.item,
    loading: state => state.loading,
    studyGroupsFiltersApplied: state =>
      (Array.isArray(state.filters.tags) && state.filters.tags.length > 0)
      || Boolean(state.filters.query)
  },

  actions: {
    async getList({ commit, state }, payload = {}) {
      if (payload.loading) {
        commit('START_GET_STUDY_GROUPS_LOADING');
      }
      const requestId = 'studyGroups';

      if (payload.isForInfinity) {
        commit('CHANGE_PAGE_STUDY_GROUPS', state.filters.page + 1);
      }

      const params = Object.fromEntries(Object.entries(state.filters).filter(([key, value]) => key && value));
      const list = await instance.get(`${settings.API_URL}/student-groups`, { requestId, params });
      
      if (payload.isForInfinity) {
        commit('SET_LIST_FOR_INFINITY', list.data);
      } else {
        commit('SET_LIST', list.data);
      }
      
      commit('FINISH_GET_STUDY_GROUPS_LOADING');
    },
    async getItem({ commit }, groupId) {
      const item = await instance.get(`${settings.API_URL}/student-groups/${groupId}`);

      commit('SET_ITEM', item.data);
    },
    async getGroupTags({ commit, state }, payload = {}) {
      const requestId = 'groupTags';

      if (payload.isForInfinity) {
        commit('CHANGE_PAGE_TAGS', state.tagsFilters.page + 1);
      }

      const params = Object.fromEntries(Object.entries(state.tagsFilters).filter(([key, value]) => key && value));
      const tags = await instance.get(`${settings.API_URL}/student-groups/tags`, { requestId, params });

      if (payload.isForInfinity) {
        commit('SET_TAGS_FOR_INFINITY', tags.data);
      } else {
        commit('SET_TAGS', tags.data);
      }
    },
    updateTagsFilters({ commit }, newFilters) {
      commit('UPDATE_TAGS_FILTERS', newFilters);
    },
    changePageStudyGroups({ commit }, page) {
      commit('CHANGE_PAGE_STUDY_GROUPS', page);
    },
    changeFiltersSearchStudyGroups({ commit }, search) {
      commit('CHANGE_FILTERS_SEARCH_STUDY_GROUPS', search);
    },
    changeFiltersForStudyGroupsTag({commit}, value) {
      commit('CHANGE_FILTERS_FOR_STUDY_GROUPS_TAG', value);
    },
    resetFiltersStudyGroups({ commit }) {
      commit('CHANGE_PAGE_STUDY_GROUPS', 1);

      commit('CHANGE_FILTERS_SEARCH_STUDY_GROUPS', null);

      commit('CHANGE_FILTERS_FOR_STUDY_GROUPS_TAG', []);
    },
    clearList({ commit }) {
      commit('CLEAR_LIST');
    },
    clearItem({ commit }) {
      commit('CLEAR_ITEM');
    },
    async create(context, payload) {
      await instance.post(`${settings.API_URL}/student-groups`, payload);
    },
    async edit({ commit }, payload) {
      const item = await instance.put(`${settings.API_URL}/student-groups/${payload.groupId}`, payload.group);

      commit('SET_ITEM', item.data);
    },
    async remove(context, payload) {
      await instance.delete(`${settings.API_URL}/student-groups/${payload.groupId}`, payload.group);
    },
    async createGroupNote(context, payload) {
      await instance.post(`${settings.API_URL}/student-groups/${payload.groupId}/notes`, payload.note);
    },
  },

  mutations: {
    SET_LIST(state, list) {
      state.list = list.student_groups;
      state.meta = list.meta;
    },
    SET_LIST_FOR_INFINITY(state, list) {
      state.list.push(...list.student_groups);
      state.meta = list.meta;
      state.filters.page++;
    },
    SET_TAGS(state, tags) {
      state.tags = tags.tags;
      state.tagsMeta = tags.meta;
    },
    SET_TAGS_FOR_INFINITY(state, tags) {
      state.tags.push(...tags.tags);
      state.tagsMeta = tags.meta;
      state.tagsFilters.page++;
    },
    UPDATE_TAGS_FILTERS(state, newFilters) {
      state.tagsFilters = { ...state.tagsFilters, ...newFilters };
    },
    SET_ITEM(state, item) {
      state.item = item.student_group;
      state.loading.item = false;
    },
    CLEAR_LIST(state) {
      state.list = [];
      state.tags = [];
      state.meta = null;
      state.filters = { page: 1, query: '', tags: [] };
      state.loading.list = true;
    },
    CLEAR_ITEM(state) {
      state.item = null;
      state.tags = [];
      state.loading.item = true;
      state.tagsFilters = { page: 1, query: '' };
    },
    START_GET_STUDY_GROUPS_LOADING(state) {
      state.loading.list = true;
    },
    FINISH_GET_STUDY_GROUPS_LOADING(state) {
      state.loading.list = false;
    },
    CHANGE_PAGE_STUDY_GROUPS(state, page) {
      state.filters.page = page;
    },
    CHANGE_PAGE_TAGS(state, page) {
      state.tagsFilters.page = page;
    },
    CHANGE_FILTERS_SEARCH_STUDY_GROUPS(state, search) {
      state.filters.query = search;
    },
    CHANGE_FILTERS_FOR_STUDY_GROUPS_TAG(state, value) {
      state.filters.tags = Array.isArray(value) ? value : [value];
    },
  },

  modules: {
    clients
  }
};