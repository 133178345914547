<template>
  <div class="account-content d-flex flex-column">
    <router-link :to="`/study-groups/${group?.student_group_id}/info?tab=clients`" class="account-back d-flex align-items-center mb-3">
      <i class="fas fa-chevron-left"></i>
      <div class="account-back-text">Назад</div>
    </router-link>
    <div class="settings-subtitle">Добавить клиентов в группу <span>{{ group?.title }}</span></div>
    <div class="groups">                     
      <div class="groups__white-box">
        <form class="groups__form">
          <p class="groups__form-title">Курс: {{ group?.course?.title }}</p>
          <div class="groups__form-row groups__form-row--v2">
            <div class="groups__form-field groups__form-field--small">
              <v-select 
                :options="tracks" 
                :clearable="false"
                :searchable="false" 
                :filterable="false"
                placeholder="Поток"
                :get-option-label='track => track.title'
                :get-option-key='track => track.track_id'
                @input="selectTrack"
                v-model.trim="activeTrack"
                style="width: 100%;"
                >
                  <template #open-indicator="{ attributes }">
                    <span class="form-control--arrow" v-bind="attributes"></span>
                  </template>
                  <template #list-footer>
                    <infinite-loading @infinite="infiniteHandler">
                      <div slot="spinner">
                        <AppLoader2 :mini="true" />
                      </div>
                      <div slot="no-more"></div>
                      <div slot="no-results"></div>
                    </infinite-loading>
                  </template>
              </v-select>
            </div>
            <transition name="fade--top">
              <div v-if="activeTrack" class="groups__form-btn groups__form-btn--small">
                <button type="button" class="groups__form-button" @click="resetTrack">Очистить</button>
              </div>
            </transition>
          </div>
        </form>
      </div>
      <div class="wrapper-for-spinner" :class="{ opacity: isLoadingPage }">
        <AppLoader3 v-if="isLoadingPage" class="wrapper-for-spinner__spinner" />

        <div v-if="clients?.length" class="groups__block">
          <div class="groups__top">
            <p class="groups__title">Клиенты в потоке</p>
            <div class="groups__top-end">
              <transition name="fade--top">
                <button type="button" class="groups__add" @click="addClients" v-if="selectedClients.length">Добавить в группу</button>
              </transition>
              <div class="groups__checkbox">
                <div class="checkbox-wrapper">
                  <p class="checkbox-title">Выбрать всех</p>
                  <div class="custom-checkbox custom-checkbox--black mr-0">                                 
                    <input type="checkbox" name="all" id="all" @change="selectAll" ref="selectAllCheckbox">
                    <label for="all" class="d-flex"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="groups__table groups__table--add">
            <ClientCard 
              v-for="client in clients" 
              :key="client.user_id" 
              :client="client" 
              :groupClients="groupClients || []"
              @openModalTransferClients="openModalTransferClients(client)"
              @removeFromGroup="removeFromGroup(client)"
              :is-selected="isAllSelected || selectedClients.includes(client.user_id)"
              @updateSelected="updateSelectedClients"
            />
          </div>

          <div v-if="clientsMeta.last_page > 1" class="d-flex justify-content-center">
            <paginate
              v-model="page"
              :pageCount="clientsMeta.last_page"
              :clickHandler="clickCallback"
              :prevText="''"
              :nextText="''"
              :containerClass="'pagination'"
              :pageClass="'page-item'"
              :pageLinkClass="'page-link'"
              :prevClass="'page-item'"
              :prevLinkClass="'page-link'"
              :nextClass="'page-item'"
              :nextLinkClass="'page-link'"
              :activeClass="'active'"
            />
          </div>
        </div>

        <p v-else style="text-align: center; padding-top: 100px;">Выберите поток</p>
      </div>
    </div>
    <ModalTransferClients
      v-if="group && clientForModal"
      :isModalTransferClients="isModalTransferClients"
      :client="clientForModal"
      @transferClient="transferClient"
      @hideModal="isModalTransferClients = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Paginate from 'vuejs-paginate';

import AppLoader3 from '@/components/AppLoader/3.vue';
import AppLoader2 from '@/components/AppLoader/2.vue';
import InfiniteLoading from 'vue-infinite-loading';

import vSelect from 'vue-select';

import ClientCard from '@/components/StudyGroups/Client/Card';
import ModalTransferClients from '@/components/ModalTransferClients.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';

export default {
  name: 'StudyGroupsClients',

  components: {
    Paginate,
    AppLoader3,
    ModalTransferClients,
    ClientCard,
    vSelect,
    AppLoader2,
    InfiniteLoading,
  },

  data() {
    return {
      page: 1,
      isLoadingPage: false,
      search: '',
      isModalTransferClients: false,
      clientForModal: null,
      activeTrack: null,
      isAllSelected: false,
      selectedClients: [],
      isLoading: false,
    }
  },

  computed: {
    ...mapGetters('studyGroups', {
      group: 'item',
    }),
    ...mapGetters('studyGroups/clients', {
      groupClients: 'list',
    }),
    ...mapGetters({
      tracks: 'coursesTracks',
      clients: 'tracksClients',
      tracksMeta: 'tracksMeta',
      clientsMeta: 'clientsMeta',
    }),
  },

  async created() {
    let id = this.$route.params.id;
    this.clearStudyGroup();
    await this.getStudyGroup(id);
    this.clearCoursesTracks();
    this.getCoursesTracks(this.group.course_id);
    this.clearGroupClients();
    this.getGroupClients(id);
  },

  destroyed() {
    this.clearStudyGroup();
    this.clearTracksClients();
    this.clearCoursesTracks();
    this.clearGroupClients();
  },

  methods: {
    ...mapActions('studyGroups', {
      getStudyGroup: 'getItem',
      clearStudyGroup: 'clearItem',
    }),
    ...mapActions('studyGroups/clients', {
      getGroupClients: 'getList',
      addClient: 'addClient',
      transferClientFromGroup: 'transferClient',
      deleteClient: 'deleteClient',
      clearGroupClients: 'clearList',
    }),
    ...mapActions([
      'getCoursesTracks',
      'getTracksClients',
      'clearCoursesTracks',
      'clearTracksClients',
      'changePageTracksClients'
    ]),
    selectTrack(track) {
      this.isLoadingPage = true;
      if (track && track.track_id) {
        this.getTracksClients(track.track_id)
        .catch((error) => {
          this.errorHandlerForResponse(error);
        })
        .finally(() => this.isLoadingPage = false);
      }
    },
    resetTrack() {
      this.isLoadingPage = true;
      this.activeTrack = null;
      
      this.clearTracksClients()
      .catch((error) => {
        this.errorHandlerForResponse(error);
      })
      .finally(() => this.isLoadingPage = false);
    },
    selectAll(event) {
      this.isAllSelected = event.target.checked;
      if (this.isAllSelected) {
        this.selectedClients = this.clients.map(client => client.user_id);
      } else {
        this.selectedClients = [];
      }
    },
    updateSelectedClients(clientId, isSelected) {
      if (isSelected) {
        this.selectedClients.push(clientId);
      } else {
        this.selectedClients = this.selectedClients.filter(id => id !== clientId);
      }
    },
    async addClients() {
      this.isLoadingPage = true;
      let payload = {
        clients: this.selectedClients,
        groupId: this.$route.params.id,
      }
      await this.addClient(payload)
      .then(() => {
        this.$toast.success('Клиент(ы) добавлены в группу');

        this.getGroupClients(payload.groupId)
        .catch((error) => {
          this.errorHandlerForResponse(error);
        })
      })
      .finally(() => {
        this.isAllSelected = false;
        this.$refs.selectAllCheckbox.checked = false;
        this.selectedClients = [];
        this.isLoadingPage = false
      });
    },
    transferClient(groupToTransfer) {
      this.isModalTransferClients = false;

      const payload = {
        groupId: this.$route.params.id,
        clientId: this.clientForModal.user_id,
        payload: {
          to: groupToTransfer
        }
      }

      this.transferClientFromGroup(payload)
        .then(() => {
          this.$toast.success('Клиент перемещен в другую группу');

          this.getGroupClients(payload.groupId)
            .catch((e) => this.errorHandlerForResponse(e))
          this.getStudyGroup(payload.groupId)
            .catch((e) => this.errorHandlerForResponse(e))
        })
        .catch((e) => {
          this.errorHandlerForResponse(e)
        });
    },
    removeFromGroup(client) {

      const payload = {
        groupId: this.$route.params.id,
        client: client.user_id,
      }

      this.deleteClient(payload)
        .then(() => {
          this.$toast.success('Клиент удален из группы');

          this.getGroupClients(payload.groupId)
            .catch((e) => this.errorHandlerForResponse(e))
          this.getStudyGroup(payload.groupId)
            .catch((e) => this.errorHandlerForResponse(e))
        })
        .catch((e) => {
          this.errorHandlerForResponse(e)
        });
    },
    openModalTransferClients(client) {
      this.clientForModal = client;
      this.isModalTransferClients = true;
    },
    infiniteHandler($state) {
      if (this.tracks.length < this.tracksMeta.total) {
        this.getCoursesTracks(this.group.course_id)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    clickCallback() {
      this.isLoadingPage = true;
      this.isAllSelected = false;
      this.$refs.selectAllCheckbox.checked = false;
      this.selectedClients = [];

      let query = Object.assign({}, this.$route.query);
      query.page = this.page;

      this.changePageTracksClients(this.page)
        .then(() => {
          this.$router.push({ query }).catch(() => {});

          const loading = false;

          this.getTracksClients(this.activeTrack.track_id, loading)
            .finally(() => this.isLoadingPage = false);
        });
    },
    errorHandlerForResponse
  }
}
</script>

<style>
.groups__table--add .groups__table-row .groups__table-item:nth-child(2) {
  display: none;
}
</style>