<template>
  <div class="account-content d-flex flex-column">
    <router-link to="/study-groups" class="account-back d-flex align-items-center mb-3">
      <i class="fas fa-chevron-left"></i>
      <div class="account-back-text">Назад</div>
    </router-link>

    <div class="d-flex align-items-center flex-wrap">
      <div class="settings-title settings-title--link d-flex align-items-center mr-5 mb-3">
        {{ studyGroup?.title }}
      </div>
      <router-link v-if="profile.user.permission.student_groups.edit" :to="`/study-groups/${studyGroup?.student_group_id}/edit`" class="settings-title settings-title--link-2 d-flex align-items-center mb-3">
        <span class="settings-title-edit"></span> Редактировать группу
      </router-link>
    </div>

    <div class="settings_filters ml-md-2 mr-md-2 mb-4">
      <div class="settings_filters-content d-flex justify-content-between justify-content-md-start">
        <div class="settings_filters-item" :class="{ active: activeTab === 'clients' }" @click="setActiveTab('clients')">
          <a href="javascript:void(0)">Клиенты</a>
        </div>
        <!-- <div class="settings_filters-item" :class="{ active: activeTab === 'documents' }" @click="setActiveTab('documents')">
          <a href="javascript:void(0)">Документы</a>
        </div> -->
        <div class="settings_filters-item" :class="{ active: activeTab === 'info' }" @click="setActiveTab('info')">
          <a href="javascript:void(0)">Информация о группе</a>
        </div>
        <div class="settings_filters-item" :class="{ active: activeTab === 'notes' }" @click="setActiveTab('notes')">
          <a href="javascript:void(0)">Заметки</a>
        </div>
      </div>
    </div>

    <AppLoader v-if="loading.item" />

    <template v-else>
      <div v-if="activeTab === 'clients'">
        <div class="d-flex align-items-center">
          <router-link v-if="studyGroup?.course_id" :to="`/study-groups/${studyGroup?.student_group_id}/add-clients`" class="settings-title settings-title--link-2 d-flex align-items-center mb-3">
            <span class="settings-title-client"></span>Добавить клиента
          </router-link>
          <!-- <div class="settings-search settings-search--exams ml-auto d-none d-md-block">
            <input type="text" class="form-control form-control--search" placeholder="Поиск по клиентам">
            <button></button>
          </div> -->
        </div>

        <AppLoader v-if="clientsLoading.list" />

        <div v-else class="groups">
          <div v-if="groupClients?.length > 0" class="groups__table groups__table--clients">
            <div class="groups__table-row groups__table-row--title">
              <div class="groups__table-item">
                <p class="groups__table-title">
                  Клиент
                </p>
              </div>
              <div class="groups__table-item">
                <p class="groups__table-title">
                  <!-- Прогресс по теории -->
                </p>
              </div>
              <div class="groups__table-item">
                <p class="groups__table-title">
                  Практические занятия
                </p>
              </div>
              <div class="groups__table-item groups__table-item--empty"></div>
            </div>
            <ClientCard 
              v-for="client in groupClients" 
              :key="client.client_id" 
              :client="client" 
              :groupClients="groupClients || []"
              @openModalTransferClients="openModalTransferClients(client)"
              @removeFromGroup="removeFromGroup(client)"
            />
          </div>
          <template v-else>
            <div v-if="studyGroup?.course_id" class="groups__create p-150">
              <router-link :to="`/study-groups/${studyGroup?.student_group_id}/add-clients`" class="groups__create-link">Добавить клиентов</router-link>
            </div>
            <div v-else class="groups__create p-150">
              <p style="text-align: center;">Чтобы добавить клиентов, пожалуйста, <br><router-link :to="`/study-groups/${studyGroup?.student_group_id}/edit`" class="groups__create-link">заполните</router-link> информацию о группе</p>
            </div>
          </template>
        </div>
        <ModalTransferClients
          v-if="studyGroup && clientForModal"
          :isModalTransferClients="isModalTransferClients"
          :client="clientForModal"
          @transferClient="transferClient"
          @hideModal="isModalTransferClients = false"
        />
      </div>

      <div v-if="activeTab === 'info'" class="groups">
        <div class="groups__white-box">
          <ul 
            v-if="studyGroup?.course || 
            studyGroup?.teachers?.length > 0 || 
            studyGroup?.instructors?.length > 0 || 
            studyGroup?.theory_start_at || 
            studyGroup?.theory_finish_at || 
            studyGroup?.practice_start_at || 
            studyGroup?.final_examination_at || 
            studyGroup?.qualifying_examination_at || 
            studyGroup?.tags?.length > 0" 
            class="groups__info"
            >
            <li v-if="studyGroup?.course" class="groups__info-item">
              <div class="groups__info-body">
                <p class="groups__info-title">Учебная программа</p>
                <p class="groups__info-text">{{ studyGroup?.course?.title }}</p>
              </div>
            </li>
            <li v-if="studyGroup?.teachers?.length" class="groups__info-item">
              <div class="groups__info-body">
                <p class="groups__info-title">Преподаватели теории</p>
                <p class="groups__info-text">
                  <span v-for="(teacher, index) in studyGroup?.teachers" :key="teacher.client_id">
                    {{ teacher.name.full }}<span v-if="index < studyGroup?.teachers?.length - 1">, </span>
                  </span>
                </p>
              </div>
            </li>
            <li v-if="studyGroup?.instructors?.length" class="groups__info-item">
              <div class="groups__info-body">
                <p class="groups__info-title">Преподаватели практики</p>
                <p class="groups__info-text">
                  <span v-for="(instructor, index) in studyGroup?.instructors" :key="instructor.instructor_id">
                    {{ instructor.name.full }}<span v-if="index < studyGroup?.instructors?.length - 1">, </span>
                  </span>
                </p>
              </div>
            </li>
            <li v-if="studyGroup?.theory_start_at && studyGroup?.theory_finish_at || studyGroup?.practice_start_at" class="groups__info-item">
              <div v-if="studyGroup?.theory_start_at && studyGroup?.theory_finish_at" class="groups__info-body">
                <p class="groups__info-title">Теория</p>
                <p v-if="studyGroup?.theory_start_at && studyGroup?.theory_finish_at" class="groups__info-text">{{ moment(studyGroup?.theory_start_at).format('DD.MM.YYYY') }} - {{ moment(studyGroup?.theory_finish_at).format('DD.MM.YYYY') }}</p>
              </div>
              <div v-if="studyGroup?.practice_start_at" class="groups__info-body">
                <p class="groups__info-title">Начало практики</p>
                <p v-if="studyGroup?.practice_start_at" class="groups__info-text">{{ moment(studyGroup?.practice_start_at).format('DD.MM.YYYY') }}</p>
              </div>
            </li>
            <li v-if="studyGroup?.final_examination_at || studyGroup?.qualifying_examination_at" class="groups__info-item">
              <div v-if="studyGroup?.final_examination_at" class="groups__info-body">
                <p class="groups__info-title">Дата итоговой аттестации</p>
                <p v-if="studyGroup?.final_examination_at" class="groups__info-text">{{ moment(studyGroup?.final_examination_at).format('DD.MM.YYYY') }}</p>
              </div>
              <div v-if="studyGroup?.qualifying_examination_at" class="groups__info-body">
                <p class="groups__info-title">Квалификационный экзамен</p>
                <p v-if="studyGroup?.qualifying_examination_at" class="groups__info-text">{{ moment(studyGroup?.qualifying_examination_at).format('DD.MM.YYYY') }}</p>
              </div>
            </li>
            <li v-if="studyGroup?.tags?.length" class="groups__info-item">
              <div class="groups__info-body">
                <p class="groups__info-title">Теги</p>
                <div class="groups__chips">
                  <div class="groups__chip" v-for="tag in studyGroup?.tags" :key="tag.tag_id">
                    <p class="groups__chip-text">{{ tag.title }}</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div v-else class="groups__create p-210">
            <router-link :to="`/study-groups/${studyGroup?.student_group_id}/edit`" class="groups__create-link">Добавить информацию о группе</router-link>
          </div>
        </div>
      </div>

      <div v-if="activeTab === 'notes'" class="groups">
        <div class="groups__white-box">
          <div class="groups__form">
            <div class="groups__form-block">
              <div class="groups__form-field groups__form-field--medium">
                <DropDownMenuCalendarNotes
                  :placeholder="'Напоминание'"
                  @changeDate="changeDate"
                  :noteAdded="noteAdded"
                />
              </div>
              <div class="groups__form-field groups__form-field--full">
                <textarea 
                  v-model.trim="note.text"
                  type="text" 
                  class="form-control form-control--bordered h-65" 
                  placeholder="Написать заметку"
                ></textarea>
              </div>
              <div class="groups__form-button">
                <button class="btn btn-blue" @click="addGroupNote" v-if="!isLoading">Добавить</button>
                <button class="btn btn-blue" disabled v-else>Добавляем...</button>
              </div>
            </div>
          </div>
          <div v-if="studyGroup?.notes?.length > 0" class="groups__notes">
            <div class="groups__notes-item" v-for="note in studyGroup?.notes" :key="note.note_id">
              <div class="groups__notes-info">
                <p v-if="note.created_at" class="groups__notes-date">{{ moment(note.created_at).format('DD.MM.YYYY') }}</p>
                <p class="groups__notes-name">{{ note.user.name.first }} {{ note.user.name.last }}</p>
              </div>
              <div class="groups__notes-content">
                <p class="groups__notes-reminder">Напоминание: {{ moment(note.date).format('DD.MM.YYYY') }}</p>
                <p class="groups__notes-text">{{ note.text }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import moment from 'moment';

import AppLoader from '@/components/AppLoader/1.vue';

import DropDownMenuCalendarNotes from '@/components/DropDownMenu/CalendarNotes.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';

import ClientCard from '@/components/StudyGroups/Client/Card.vue';
import ModalTransferClients from '@/components/ModalTransferClients.vue';

export default {
  name: 'StudyGroupsInfo',

  components: {
    AppLoader,
    DropDownMenuCalendarNotes,
    ClientCard,
    ModalTransferClients,
  },

  data() {
    return {
      activeTab: 'info',
      note: {
        text: '',
        date: '',
      },
      noteAdded: false,
      isLoading: false,
      isModalTransferClients: false,
      clientForModal: null,
    }
  },

  computed: {
    ...mapGetters('studyGroups', {
      studyGroup: 'item',
      loading: 'loading',
    }),
    ...mapGetters('studyGroups/clients', {
      groupClients: 'list',
      clientsLoading: 'loading',
    }),
    ...mapGetters(['profile']),
  },

  async created() {
    let id = this.$route.params.id;

    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab;
    }

    await this.getStudyGroup(id);
    this.getClients(id);
  },

  destroyed() {
    this.clearStudyGroup();
    this.clearClients();
  },

  methods: {
    ...mapActions('studyGroups', {
      getStudyGroup: 'getItem',
      clearStudyGroup: 'clearItem',
      createGroupNote: 'createGroupNote'
    }),
    ...mapActions('studyGroups/clients', {
      getClients: 'getList',
      transferClientFromGroup: 'transferClient',
      deleteClient: 'deleteClient',
      clearClients: 'clearList',
    }),
    setActiveTab(tab) {
      this.activeTab = tab;

      this.$router.push({ 
        query: { tab: this.activeTab } 
      });
    },
    changeDate(date) {
      this.note.date = date;
      this.noteAdded = false;
    },
    addGroupNote() {
      this.isLoading = true;
      let payload = {
        note: this.note,
        groupId: this.$route.params.id,
      }
      this.createGroupNote(payload).then(() => {
        this.isLoading = false;
        this.getStudyGroup(payload.groupId);
        this.note.text = '';
        this.noteAdded = true;
      }).catch((e) => {
				this.isLoading = false;
				this.errorHandlerForResponse(e);
			})
    },
    openModalTransferClients(client) {
      this.clientForModal = client;
      this.isModalTransferClients = true;
    },
    transferClient(groupToTransfer) {
      this.isModalTransferClients = false;

      const payload = {
        groupId: this.$route.params.id,
        clientId: this.clientForModal.client_id,
        payload: {
          to: groupToTransfer
        }
      }

      this.transferClientFromGroup(payload)
        .then(() => {
          this.$toast.success('Клиент перемещен в другую группу');

          this.getClients(payload.groupId)
            .catch((e) => this.errorHandlerForResponse(e))
          this.getStudyGroup(payload.groupId)
            .catch((e) => this.errorHandlerForResponse(e))
        })
        .catch((e) => {
          this.errorHandlerForResponse(e)
        });
    },
    removeFromGroup(client) {

      const payload = {
        groupId: this.$route.params.id,
        client: client.client_id,
      }

      this.deleteClient(payload)
        .then(() => {
          this.$toast.success('Клиент удален из группы');

          this.getClients(payload.groupId)
            .catch((e) => this.errorHandlerForResponse(e))
          this.getStudyGroup(payload.groupId)
            .catch((e) => this.errorHandlerForResponse(e))
        })
        .catch((e) => {
          this.errorHandlerForResponse(e)
        });
    },
    moment,
    errorHandlerForResponse
  }
}
</script>

<style lang="scss">
.groups__table-more-dropdown {
  display: flex !important;
}
</style>