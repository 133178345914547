<template>
  <div class="account-content">
    <div class="settings-top d-md-flex align-items-end">
      <GoBack />
    </div>

    <div class="d-flex">
      <div class="account-subtitle account-subtitle--large pt-0 mb-3">
        <span class="light">Создать новую группу</span>
      </div>
    </div>

    <!-- <div class="school-cabinet">
      <div class="school-cabinet_white-box">
        <div class="school-cabinet_info-field form-group mb-4">
          <input
            v-model="payload.name"
            @keyup.enter="groupCreate"
            type="text"
            placeholder="Название группы"
            class="form-control form-control--bordered"
          >
        </div>

        <SearchClients :isCreate="true" @groupCreate="groupCreate" />
      </div>
    </div> -->

    <div class="school-cabinet_info-form school-cabinet_info-form--white-box school-cabinet_info-form--full">
      <div class="school-cabinet_info-row">
        <div class="d-flex justify-content-between flex-sm-row flex-column gap-3">
          <div class="school-cabinet_info-field school-cabinet_info-field--full my-sm-0">
            <input
              v-model="title"
              type="text"
              placeholder="Название группы"
              class="form-control form-control--bordered"
            >
          </div>
          <div class="mx-sm-3">
            <button class="btn btn-blue btn-full h-44" @click="addStudyGroup" v-if="!isLoading">Создать</button>
            <button class="btn btn-blue btn-full h-44" disabled v-else>Создаем...</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import GoBack from '@/components/GoBack.vue';

// import SearchClients from '@/components/StudyGroups/SearchClients.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';

export default {
  name: 'StudyGroupsCreate',

  components: {
    GoBack,
    // SearchClients
  },

  data() {
    return {
      isLoading: false,
      title: '',
    }
  },

  methods: {
    ...mapActions('studyGroups', {
      createGroup: 'create'
    }),
    addStudyGroup() {
      this.isLoading = true;
      let payload = {
        'title': this.title
      }
      this.createGroup(payload).then(() => {
        this.isLoading = false;
        this.$router.push('/study-groups');

      }).catch((e) => {
				this.isLoading = false;
				this.errorHandlerForResponse(e);
			})
    },
    errorHandlerForResponse
  }
}
</script>