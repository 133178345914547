<template>
  <div class="groups__table-row">
    <div class="groups__table-item groups__table-item--full">
      <div class="groups__table-user">
          <div class="groups__table-user-image">
            <img v-if="client.avatar && client.avatar.url" :src="client.avatar.url" alt="" class="groups__table-user-img">
            <img v-if="client.avatar_url" :src="client.avatar_url" alt="" class="groups__table-user-img">
            <img v-if="!client?.avatar?.url && !client?.avatar_url" src="@/assets/img/noavatar.svg" alt="" class="groups__table-user-img">
          </div>
          <div class="groups__table-user-body">
            <p class="groups__table-user-name">{{ client.name?.last }} {{ client.name?.first }}</p>
            <p class="groups__table-user-info">{{ client.email }}<template v-if="client.email && client.phone">,</template> {{ client.phone }}</p>
          </div>
      </div>
    </div>
    <div class="groups__table-item">
      <p class="groups__table-title mob-only">
        <!-- Прогресс по теории -->
      </p>
      <div class="groups__table-progress">
        <span class="groups__table-progress-item"></span>
        <span class="groups__table-progress-item"></span>
        <span class="groups__table-progress-item"></span>
        <span class="groups__table-progress-item"></span>
        <span class="groups__table-progress-item"></span>
      </div>
    </div>
    <div class="groups__table-item">
      <div v-if="client.statistics" class="groups__table-btn">
        <div class="groups__table-btn-icon wheel"></div>
        <div class="groups__table-btn-title">{{ client.statistics.lessons.total }}</div>
      </div>
      <div v-if="client.client_id && !client.statistics" class="groups__table-btn">
        <div class="groups__table-btn-icon wheel"></div>
        <div class="groups__table-btn-title">0</div>
      </div>
    </div>
    <template v-if="client.user_id">
      <div v-if="isClientMatch" class="groups__table-item">
        <div class="groups__table-added"></div>
        <div ref="menuBlock" class="groups__table-more">
          <button type="button" class="groups__table-more-btn" @click="isMenuBlock = !isMenuBlock"></button>
          <transition name="fade">
            <ul class="groups__table-more-dropdown" v-if="isMenuBlock" :class="{ active: isMenuBlock }">
              <li class="groups__table-more-item"><a href="javascript:void(0)" class="groups__table-more-link" @click="openModalTransferClients">Перевести в другую группу</a></li>
              <li class="groups__table-more-item"><a href="javascript:void(0)" class="groups__table-more-link" @click="removeFromGroup">Убрать из группы</a></li>
            </ul>
          </transition>
        </div>
      </div>
      <div v-else class="groups__table-item">
        <div class="groups__checkbox">
          <div class="custom-checkbox mr-0">                                 
            <input 
              type="checkbox" 
              name="client" 
              :id="`client-${client.user_id}`"
              :checked="isSelected"
              @change="toggleClientSelect"
            >
            <label :for="`client-${client.user_id}`" class="d-flex"></label>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="groups__table-item">
      <div ref="menuBlock" class="groups__table-more">
        <button type="button" class="groups__table-more-btn" @click="isMenuBlock = !isMenuBlock"></button>
        <transition name="fade">
          <ul class="groups__table-more-dropdown" v-if="isMenuBlock" :class="{ active: isMenuBlock }">
            <li class="groups__table-more-item"><a href="javascript:void(0)" class="groups__table-more-link" @click="openModalTransferClients">Перевести в другую группу</a></li>
            <li class="groups__table-more-item"><a href="javascript:void(0)" class="groups__table-more-link" @click="removeFromGroup">Убрать из группы</a></li>
          </ul>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudyGroupsClientCard',

  props: {
    client: {
      type: Object,
      required: true,
      default: () => {}
    },
    groupClients: {
      type: Array,
      required: true,
      default: () => []
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  data() {
    return {
      isMenuBlock: false
    }
  },

  computed: {
    isClientMatch() {
      return this.groupClients?.some(client => client.client_id === this.client.user_id);
    }
  },

  mounted() {
    document.addEventListener('click', this.hideMenuBlock);
  },

  destroyed() {
    document.removeEventListener('click', this.hideMenuBlock);
  },

  methods: {
    removeFromGroup() {
      this.$emit('removeFromGroup');
      this.isMenuBlock = false;
    },
    hideMenuBlock(event) {
      if (this.$refs.menuBlock?.contains(event.target)) {
        return;
      } else {
        this.isMenuBlock = false;
      }
    },
    openModalTransferClients() {
      this.$emit('openModalTransferClients');
      this.isMenuBlock = false;
    },
    toggleClientSelect(event) {
      this.$emit('updateSelected', this.client.user_id, event.target.checked);
    }
  },
}
</script>

<style>
.groups__table-btn-icon.wheel {
  background-image: url(~@/assets/img/my-clients-active.svg);
  width: 23px;
  min-width: 23px;
  height: 23px;
}
</style>