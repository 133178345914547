<template>
  <div class="settings-body settings-body--height-auto pl-4 pr-4">
    <div class="settings__note">
      <DropDownMenuCalendarNotes
        :noteAdded="noteAdded"
        @changeDate="changeDate"
        class="mb-3"
      />

      <div class="settings__note-field">
        <textarea
          v-model.trim="note.note.text"
          cols="30"
          rows="10"
          class="form-control form-control--bordered"
        ></textarea>
      </div>

      <div class="settings__note-button mt-3">
        <button v-if="loadingAddNoteAndGetNotes" class="btn btn-blue btn-blue--custom">
          <AppLoader2 :mini="true" :color="'#ffffff'" />
        </button>

        <button v-else @click="createNote" class="btn btn-blue btn-blue--custom">Добавить</button>
      </div>

      <template v-if="notes.length">
        <ClientNotesCard
          v-for="note in notes"
          :key="note.note_id"
          :note="note"
        />

        <infinite-loading
          :identifier="infiniteId"
          @infinite="infiniteHandler"
          class="mt-4"
        >
          <div slot="spinner">
            <AppLoader2 :mini="true" />
          </div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import InfiniteLoading from 'vue-infinite-loading';

import AppLoader2 from '@/components/AppLoader/2.vue';
import ClientNotesCard from '@/components/Client/Notes/Card.vue';
import DropDownMenuCalendarNotes from '@/components/DropDownMenu/CalendarNotes.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';

export default {
  name: 'ClientNotes',

  components: {
    AppLoader2,
    InfiniteLoading,
    ClientNotesCard,
    DropDownMenuCalendarNotes
  },

  data() {
    return {
      note: {
        note: {
          text: '',
          remind_at: ''
        }
      },
      noteAdded: false,
      loadingAddNoteAndGetNotes: false,
      infiniteId: +new Date(),
    }
  },

  computed: {
    ...mapGetters({
      notesAllData: 'clientNotesAllData',
      notes: 'clientNotes',
    })
  },

  beforeDestroy() {
    this.clearClientNotes();
    this.resetPageClientNotes();
  },

  methods: {
    ...mapActions([
      'getClientNotes',
      'clearClientNotes',
      'createClientNote',
      'resetPageClientNotes',
    ]),
    createNote() {
      if (!this.note.note.text) {
        this.$toast.open({
          message: 'Введите текст заметки',
          type: 'warning'
        });

        return;
      }
      if (!this.note.date) {
        this.$toast.open({
          message: 'Ввыберите дату напоминания',
          type: 'warning'
        });

        return;
      }

      this.loadingAddNoteAndGetNotes = true;

      const payload = {
        idClient: this.$route.params.id,
        note: this.note
      }

      this.createClientNote(payload)
        .then(() => {
          const payload = {
            idClient: this.$route.params.id,
            loading: false,
            setNotesAfterCreate: true
          }

          this.getClientNotes(payload)
            .then(() => {
              this.infiniteId++;
              this.note.note.text = '';
              this.noteAdded = true;

              this.$toast.open({
                message: 'Заметка создана',
                type: 'success'
              });
            })
            .catch((error) => {
              this.errorHandlerForResponse(error);
            })
            .finally(() => {
              this.loadingAddNoteAndGetNotes = false;
            });
        })
        .catch((error) => {
          this.loadingAddNoteAndGetNotes = false;

          this.errorHandlerForResponse(error);
        });
    },
    changeDate(date) {
      this.note.note.remind_at = date;
      this.noteAdded = false;
    },
    infiniteHandler($state) {
      if (this.notes.length < this.notesAllData.meta.entries.total && this.notesAllData.notes.length) {
        const payload = {
          idClient: this.$route.params.id,
          loading: false
        }

        this.getClientNotes(payload)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    errorHandlerForResponse
  }
}
</script>