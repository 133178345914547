import instance from '../instance';
import settings from '../settings';

export default {
  state: {
    courses: [],
    coursesTracks: [],
    tracksClients: [],
    coursesMeta: {},
    tracksMeta: {},
    clientsMeta: {},
    loading: {
      courses: true,
      coursesTracks: true,
      tracksClients: true,
    },
    coursesFilters: {
      page: 1,
      page_size: 10,
    },
    tracksFilters: {
      page: 1,
      page_size: 10,
    },
    clientsFilters: {
      page: 1,
      page_size: 20,
    },
  },

  getters: {
    courses: state => state.courses,
    coursesTracks: state => state.coursesTracks,
    tracksClients: state => state.tracksClients,
    coursesLoading: state => state.loading,
    coursesFilters: state => state.coursesFilters,
    tracksFilters: state => state.tracksFilters,
    clientsFilters: state => state.clientsFilters,
    coursesMeta: state => state.coursesMeta,
    tracksMeta: state => state.tracksMeta,
    clientsMeta: state => state.clientsMeta,
  },

  actions: {
    async getCourses({ commit, state }) {
      const requestId = 'coursesFilter';

      const params = Object.fromEntries(Object.entries(state.coursesFilters).filter(([key, value]) => key && value));

      const courses = await instance.get(`${settings.API_URL}/courses`, { requestId, params } );

      commit('SET_COURSES', courses.data);
    },

    async getCoursesTracks({ commit, state }, courseId) {
      const requestId = 'tracksFilter';

      const params = Object.fromEntries(Object.entries(state.tracksFilters).filter(([key, value]) => key && value));

      const coursesTracks = await instance.get(`${settings.API_URL}/courses/${courseId}/tracks`, { requestId, params });

      commit('SET_COURSES_TRACKS', coursesTracks.data);
    },

    async getTracksClients({ commit, state }, trackId) {
      const requestId = 'clientsFilter';

      const params = Object.fromEntries(Object.entries(state.clientsFilters).filter(([key, value]) => key && value));

      const tracksClients = await instance.get(`${settings.API_URL}/tracks/${trackId}/users`, { requestId, params });

      commit('SET_TRACKS_CLIENTS', tracksClients.data);
    },

    changePageTracksClients({ commit }, page) {
      commit('CHANGE_PAGE_TRACKS_CLIENTS', page);
    },

    clearCourses({ commit }) {
      commit('CLEAR_COURSES');
    },

    clearCoursesTracks({ commit }) {
      commit('CLEAR_COURSES_TRACKS');
    },

    clearTracksClients({ commit }) {
      commit('CLEAR_TRACKS_CLIENTS');
    },
  },

  mutations: {
    SET_COURSES(state, courses) {
      state.courses.push(...courses.data);
      state.coursesMeta = courses;
      state.coursesFilters.page++;
    },
    SET_COURSES_TRACKS(state, coursesTracks) {
      state.coursesTracks.push(...coursesTracks.data);
      state.tracksMeta = coursesTracks;
      state.tracksFilters.page++;
    },
    SET_TRACKS_CLIENTS(state, tracksClients) {
      state.tracksClients = tracksClients.data;
      state.clientsMeta = tracksClients;
    },
    CHANGE_PAGE_TRACKS_CLIENTS(state, page) {
      state.clientsFilters.page = page;
    },
    CLEAR_COURSES(state) {
      state.courses = [];
      state.coursesMeta = {};
      state.coursesFilters = { page: 1, page_size: 10 };
      state.loading.courses = true;
    },
    CLEAR_COURSES_TRACKS(state) {
      state.coursesTracks = [];
      state.tracksMeta = {};
      state.tracksFilters = { page: 1, page_size: 10 };
      state.loading.coursesTracks = true;
    },
    CLEAR_TRACKS_CLIENTS(state) {
      state.tracksClients = [];
      state.clientsMeta = {};
      state.loading.tracksClients = true;
    },
  },
};