import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import profile from './modules/profile';
import instructors from './modules/instructors';
import clients from './modules/clients';
import assignment from './modules/assignment';
import analytics from './modules/analytics';
import administrators from './modules/administrators';
import dict from './modules/dict';
import errors from './modules/errors';
import clientsFilter from './modules/filters/clients';
import usersFilter from './modules/filters/users';
import activity from './modules/activity';
import transactions from './modules/transactions';
import acts from './modules/acts';
import school from './modules/school';
import studyGroups from './modules/studyGroups';
import reports from './modules/reports';
import orders from './modules/orders';
import courses from './modules/courses';


Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    auth,
    profile,
    instructors,
    clients,
    assignment,
    analytics,
    administrators,
    dict,
    errors,
    clientsFilter,
    usersFilter,
    activity,
    transactions,
    school,
    studyGroups,
    acts,
    reports,
    orders,
    courses,
  },
  strict: debug,
});
