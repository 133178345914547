<template>
  <div class="account-content d-flex flex-column">
    <div class="settings">
      <div class="settings-top d-md-flex align-items-end">
        <div class="settings-head d-flex align-items-center">
          <div class="settings-title">Группы обучения</div>
          <router-link v-if="profile.user.permission.student_groups.create" to="/study-groups/create" class="settings-head-icon"></router-link>
        </div>
        <div class="settings-search ml-auto d-flex mt-4 mt-md-0">
          <input
            v-model.trim="search"
            v-debounce:1000ms="searchGroups"
            type="text"
            class="form-control form-control--search pl-2 pl-md-2"
            placeholder="Поиск по группам"
          >
          <button type="button"></button>
        </div>
      </div>
    </div>

    <div class="filters d-flex flex-wrap filters--exams align-items-center">
      <!-- <div class="filters-item mb-2 mb-md-0">
        <DropDownMenuCalendar
          :placeholder="'Период теории'"
          :dateCalendar="dateCalendar"
          :disabled="disabledFilters"
          @changeTheoryDate="changeTheoryDate"
        />
      </div>
      <div class="filters-item mb-2 mb-md-0">
        <DropDownMenuCalendar
          :placeholder="'Период практики'"
          :dateCalendar="dateCalendar"
          :disabled="disabledFilters"
          @changePracticeDate="changePracticeDate"
        />
      </div> -->
      <div class="filters-item mb-2 mb-md-0">
        <v-select 
          :options="tags" 
          :clearable="false"
          :searchable="false" 
          :filterable="false"
          :disabled="disabledFilters"
          placeholder="Поиск по тегу"
          :get-option-label='tag => tag.title'
          :get-option-key='tag => tag.title'
          @input="selectTag"
          v-model.trim="activeTag"
          >
            <template #open-indicator="{ attributes }">
              <span class="form-control--arrow" v-bind="attributes"></span>
            </template>
            <template #list-footer>
              <infinite-loading @infinite="infiniteHandlerTags">
                <div slot="spinner">
                  <AppLoader2 :mini="true" />
                </div>
                <div slot="no-more"></div>
                <div slot="no-results"></div>
              </infinite-loading>
            </template>
        </v-select>
      </div>
      <transition name="fade--top">
        <button
          v-if="filtersApplied"
          @click="resetFilters"
          type="button"
          class="reset-filters filters-item--100 align-self-center mt-3 mb-3 mt-xl-0 mb-md-0 ml-xl-auto"
        >
          Сбросить фильтры
        </button>
      </transition>
    </div>

    <AppLoader v-if="loading.list" />

    <div v-else class="groups">
      <div class="wrapper-for-spinner" :class="{ opacity: isLoadingPage }">
        <AppLoader3 v-if="isLoadingPage" class="wrapper-for-spinner__spinner" />

        <template v-if="studyGroups?.length">
          <div class="groups__table">
            <div class="groups__table-wrapper">
              <div class="groups__table-row groups__table-row--title">
                <div class="groups__table-item">
                  <p class="groups__table-title">
                    Группа
                  </p>
                </div>
                <div class="groups__table-item">
                  <p class="groups__table-title">
                    Теория
                  </p>
                </div>
                <div class="groups__table-item">
                  <p class="groups__table-title">
                    Начало практики
                  </p>
                </div>
                <div class="groups__table-item">
                  <p class="groups__table-title">
                    Итоговая аттестация
                  </p>
                </div>
                <div class="groups__table-item">
                  <p class="groups__table-title">
                    Квалиф. экзамен
                  </p>
                </div>
                <div class="groups__table-item">
                  <p class="groups__table-title">
                    Клиенты
                  </p>
                </div>
              </div>
              <div class="groups__table-row" v-for="group in studyGroups" :key="group.student_group_id">
                <div class="groups__table-item">
                  <p class="groups__table-title mob-only">
                    Группа:
                  </p>
                  <router-link :to="`/study-groups/${group.student_group_id}/info?tab=info`" class="groups__table-value groups__table-value--main">
                    <span v-if="group.code">{{ group.code }},</span> {{ group.title }}
                  </router-link>
                </div>
                <div class="groups__table-item">
                  <p class="groups__table-title mob-only">
                    Теория:
                  </p>
                  <p v-if="group.theory_start_at && group.theory_finish_at" class="groups__table-value">
                    {{ moment(group.theory_start_at).format('DD.MM.YY') }} - {{ moment(group.theory_finish_at).format('DD.MM.YY') }}
                  </p>
                </div>
                <div class="groups__table-item">
                  <p class="groups__table-title mob-only">
                    Начало практики:
                  </p>
                  <p v-if="group.practice_start_at" class="groups__table-value">
                    {{ moment(group.practice_start_at).format('DD.MM.YY') }}
                  </p>
                </div>
                <div class="groups__table-item">
                  <p class="groups__table-title mob-only">
                    Итоговая аттестация:
                  </p>
                  <p v-if="group.final_examination_at" class="groups__table-value">
                    {{ moment(group.final_examination_at).format('DD.MM.YY') }}
                  </p>
                </div>
                <div class="groups__table-item">
                  <p class="groups__table-title mob-only">
                    Квалиф. экзамен:
                  </p>
                  <p v-if="group.qualifying_examination_at" class="groups__table-value">
                    {{ moment(group.qualifying_examination_at).format('DD.MM.YY') }}
                  </p>
                </div>
                <div class="groups__table-item">
                  <p class="groups__table-title mob-only">
                    Клиенты:
                  </p>
                  <router-link :to="`/study-groups/${group.student_group_id}/info?tab=clients`" class="groups__table-btn">
                    <div class="groups__table-btn-icon"></div>
                    <div class="groups__table-btn-title">{{ group?.clients_count }}</div>
                  </router-link>
                </div>
                <div v-if="group.tags" class="groups__table-item groups__table-item--full">
                  <div class="groups__table-tags">
                    <p class="groups__table-tag" v-for="tag in group.tags" :key="tag.tag_id">{{ tag.title }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="meta.page.total > 1" class="d-flex justify-content-center">
            <paginate
              v-model="page"
              :pageCount="meta.page.total"
              :clickHandler="clickCallback"
              :prevText="''"
              :nextText="''"
              :containerClass="'pagination'"
              :pageClass="'page-item'"
              :pageLinkClass="'page-link'"
              :prevClass="'page-item'"
              :prevLinkClass="'page-link'"
              :nextClass="'page-item'"
              :nextLinkClass="'page-link'"
              :activeClass="'active'"
            />
          </div>
        </template>
        
        <div v-else class="groups__create">
          <span class="groups__create-text">Групп не найдено</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import moment from 'moment';

import Paginate from 'vuejs-paginate';
import vSelect from 'vue-select';
// import _ from 'lodash';

import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader2 from '@/components/AppLoader/2.vue';
import AppLoader3 from '@/components/AppLoader/3.vue';
import InfiniteLoading from 'vue-infinite-loading';

// import DropDownMenuCalendar from '@/components/DropDownMenu/Calendar.vue';

export default {
  name: 'StudyGroups',

  components: {
    Paginate,
    vSelect,
    AppLoader,
    AppLoader2,
    AppLoader3,
    InfiniteLoading,
    // DropDownMenuCalendar,
  },

  data() {
    return {
      page: 1,
      isLoadingPage: false,
      disabledFilters: false,
      search: '',
      activeTag: null,
    }
  },

  computed: {
    ...mapGetters('studyGroups', {
      studyGroups: 'list',
      meta: 'meta',
      loading: 'loading',
      tags: 'tags',
      filtersApplied: 'studyGroupsFiltersApplied',
      tagsMeta: 'tagsMeta',
    }),
    ...mapGetters(['profile']),
  },

  async created() {
    this.clearStudyGroups();
    await this.getStudyGroups();
    this.getTags();
  },

  destroyed() {
    this.clearStudyGroups();
    this.resetFiltersStudyGroups();
  },

  methods: {
    ...mapActions('studyGroups', {
      getStudyGroups: 'getList',
      clearStudyGroups: 'clearList',
      getTags: 'getGroupTags',
      changePageStudyGroups: 'changePageStudyGroups',
      changeFiltersSearchStudyGroups: 'changeFiltersSearchStudyGroups',
      changeFiltersForStudyGroupsTag: 'changeFiltersForStudyGroupsTag',
      resetFiltersStudyGroups: 'resetFiltersStudyGroups',
    }),
    clickCallback() {
      this.isLoadingPage = true;

      let query = Object.assign({}, this.$route.query);
      query.page = this.page;

      this.changePageStudyGroups(this.page)
        .then(() => {
          this.$router.push({ query }).catch(() => {});

          const loading = false;

          this.getStudyGroups(loading)
            .finally(() => this.isLoadingPage = false);
        });
    },
    infiniteHandler($state) {
      if (this.clients.length < this.filterClientsMeta.entries.total) {
        this.getFilterClients(false)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    infiniteHandlerTags($state) {
      if (this.tags.length < this.tagsMeta.entries.total) {
        this.getTags({isForInfinity: true})
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    searchGroups(value) {
      this.isLoadingPage = true;

      const search = value.trim();

      this.changePageStudyGroups(1);
      this.page = 1;

      let query = Object.assign({}, this.$route.query);
      query.page = '1';

      if (!search) {
        delete query.search;
      } else {
        query.search = search;
      }

      this.$router.push({ query }).catch(() => {});

      this.changeFiltersSearchStudyGroups(search);

      const loading = false;

      this.getStudyGroups(loading)
        .finally(() => this.isLoadingPage = false);
    },
    selectTag(tag) {
      this.isLoadingPage = true;
      this.disabledFilters = true;
      this.changeFiltersForStudyGroupsTag([tag.title]);

      this.changePageStudyGroups(1);

      const loading = false;
      this.getStudyGroups(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });
    },
    resetFilters() {
      if (this.disabledFilters) {
        return;
      }

      this.isLoadingPage = true;
      this.disabledFilters = true;
      this.activeTag = null;
      this.search = '';

      this.resetFiltersStudyGroups();

      const loading = false;

      this.getStudyGroups(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      delete query.tags;
      this.$router.push({ query }).catch(() => {});
    },
    moment
  }
}
</script>

<style>
.groups__create-text {
  color: #868686;
}
</style>