<template>
  <div class="account-content d-flex flex-column">
    <router-link :to="`/study-groups/${studyGroup?.student_group_id}/info?tab=info`" class="account-back d-flex align-items-center mb-3">
      <i class="fas fa-chevron-left"></i>
      <div class="account-back-text">Назад</div>
    </router-link>
    <p class="settings-subtitle">Информация о группе</p>

    <AppLoader v-if="loading.item && coursesLoading.courses" />

    <div v-else class="groups">
      <div class="groups__white-box">
        <form class="groups__form">
          <div class="groups__form-block">
            <p class="groups__form-title">Основная информация</p>
            <div class="groups__form-row">
              <div class="groups__form-field groups__form-field--small">
                <p class="form-label">Название группы</p>
                <input 
                  v-model.trim="group.title"
                  type="text" 
                  class="form-control form-control--bordered"
                >
              </div>
              <div class="groups__form-field groups__form-field--small">
                <p class="form-label">Номер группы</p>
                <input 
                  v-model.trim="group.code"
                  type="text" 
                  class="form-control form-control--bordered"
                >
              </div>
              <div class="groups__form-field groups__form-field--small">
                <p class="form-label">Учебная программа группы</p>
                <v-select 
                  :options="courses" 
                  :reduce="course => course.course_id" 
                  v-model.trim="group.course_id" 
                  :clearable="false"
                  :searchable="false" 
                  :filterable="false"
                  placeholder="Выбрать программу"
                  :get-option-label='course => course.title'
                  style="width: 100%;"
                  >
                    <template #open-indicator="{ attributes }">
                      <span class="form-control--arrow" v-bind="attributes"></span>
                    </template>
                    <template #list-footer>
                      <infinite-loading @infinite="infiniteHandlerCourse">
                        <div slot="spinner">
                          <AppLoader2 :mini="true" />
                        </div>
                        <div slot="no-more"></div>
                        <div slot="no-results"></div>
                      </infinite-loading>
                    </template>
                </v-select>
              </div>
            </div>
            <div class="groups__form-item">
              <div class="groups__form-row">
                <div class="groups__form-field--medium">
                  <p class="form-label">Преподаватели теории</p>
                  <v-select
                    :options="filteredTeachers"
                    @search="searchClient"
                    :clearable="false"
                    :searchable="true"
                    :filterable="false"
                    placeholder="Выбрать преподавателя"
                    v-model.trim="teacher"
                    @input="addTeacher"
                  >
                    <template #open-indicator="{ attributes }">
                      <span class="form-control--arrow" v-bind="attributes"></span>
                    </template>
                    <template slot="no-options">
                      Преподавателей не найдено
                    </template>
                    <template v-slot:option="option">
                      {{ option.name.last }} {{ option.name.first }} {{ option.name?.middle }}
                    </template>
                    <template #spinner="{ loading }">
                      <AppLoader2 :mini="true" v-if="loading" />
                    </template>
                    <template #list-footer>
                      <infinite-loading @infinite="infiniteHandlerClient">
                        <div slot="spinner">
                          <AppLoader2 :mini="true" />
                        </div>
                        <div slot="no-more"></div>
                        <div slot="no-results"></div>
                      </infinite-loading>
                    </template>
                  </v-select>
                </div>
              </div>
              <div v-if="studyGroup?.teachers" class="groups__chips">
                <div class="groups__chip" v-for="teacher in selectedTeachersChips" :key="teacher.client_id">
                  <p class="groups__chip-text">{{ teacher.name.full }}</p>
                  <button class="groups__chip-btn" @click="closeTeacherChip(teacher.client_id)"></button>
                </div>
              </div>
            </div>
            <div class="groups__form-item">
              <div class="groups__form-row">
                <div class="groups__form-field--medium">
                  <p class="form-label">Преподаватели практики *</p>
                  <v-select
                    :options="filteredInsctructors"
                    @search="searchInstructor"
                    :clearable="false"
                    :searchable="true"
                    :filterable="false"
                    placeholder="Выбрать преподавателя"
                    v-model.trim="instructor"
                    @input="addInstructor"
                  >
                    <template #open-indicator="{ attributes }">
                      <span class="form-control--arrow" v-bind="attributes"></span>
                    </template>
                    <template slot="no-options">
                      Преподавателей не найдено
                    </template>
                    <template v-slot:option="option">
                      {{ option.name.last }} {{ option.name.first }} {{ option.name?.middle }}
                    </template>
                    <template #spinner="{ loading }">
                      <AppLoader2 :mini="true" v-if="loading" />
                    </template>
                    <template #list-footer>
                      <infinite-loading @infinite="infiniteHandlerInstructor">
                        <div slot="spinner">
                          <AppLoader2 :mini="true" />
                        </div>
                        <div slot="no-more"></div>
                        <div slot="no-results"></div>
                      </infinite-loading>
                    </template>
                  </v-select>
                </div>
              </div>
              <div v-if="studyGroup?.instructors" class="groups__chips">
                <div class="groups__chip" v-for="instructor in selectedInstructorsChips" :key="instructor.instructor_id">
                  <p class="groups__chip-text">{{ instructor.name.full }}</p>
                  <button class="groups__chip-btn" @click="closeInstructorChip(instructor.instructor_id)"></button>
                </div>
              </div>
            </div>
          </div>
          <div class="groups__form-block">
            <p class="groups__form-title">Даты теории и практики</p>
            <div class="groups__form-row">
              <div class="groups__form-field groups__form-field--small">
                <p class="form-label">Начало теоретического обучения</p>
                <input
                  v-model.trim="group.theory_start_at"
                  type="text"
                  class="form-control form-control--calendar-icon form-control--bordered"
                  id="calendar-modal-theory-start"
                >
              </div>
              <div class="groups__form-field groups__form-field--small">
                <p class="form-label">Конец теоретического обучения</p>
                <input
                  v-model.trim="group.theory_finish_at"
                  type="text"
                  class="form-control form-control--calendar-icon form-control--bordered"
                  id="calendar-modal-theory-finish"
                >
              </div>
              <div class="groups__form-field groups__form-field--small">
                <p class="form-label">Начало практики *</p>
                <input
                  v-model.trim="group.practice_start_at"
                  type="text"
                  class="form-control form-control--calendar-icon form-control--bordered"
                  id="calendar-modal-practice-start"
                >
              </div>
            </div>
          </div>
          <div class="groups__form-block">
            <p class="groups__form-title">Экзамены</p>
            <div class="groups__form-row">
              <div class="groups__form-field groups__form-field--small">
                <p class="form-label">Итоговая аттестация </p>
                <input
                  v-model.trim="group.final_examination_at"
                  type="text"
                  class="form-control form-control--calendar-icon form-control--bordered"
                  id="calendar-modal-final-examination"
                >
              </div>
              <div class="groups__form-field groups__form-field--small">
                <p class="form-label">Квалификационный экзамен</p>
                <input
                  v-model.trim="group.qualifying_examination_at"
                  type="text"
                  class="form-control form-control--calendar-icon form-control--bordered"
                  id="calendar-modal-qualifying-examination"
                >
              </div>
              <div class="groups__form-field groups__form-field--small">
              </div>
            </div>
          </div>
          <div class="groups__form-block">
            <p class="groups__form-title">Теги</p>
            <div class="groups__form-item">
              <div class="groups__form-row groups__form-row--always">
                <div ref="dropdownList" class="groups__form-field groups__form-field--tag groups__form-field--medium">
                  <input 
                    type="text" 
                    class="form-control form-control--bordered" 
                    placeholder="Название тега"
                    v-model.trim="tag"
                    v-debounce:1000ms="onInput"
                  >
                  <AppLoader2 :mini="true" v-if="isLoadingSpinner" />
                  <transition name="fade--top">
                    <ul v-if="tags.length && isDropdownVisible" class="groups__form-dropdown">
                      <li 
                        class="groups__form-dropdown-item" 
                        v-for="tag in filteredTags" 
                        :key="tag.tag_id"
                        @click="selectTag(tag)"
                      >
                        {{ tag.title }}
                      </li>
                    </ul>
                  </transition>
                </div>
                <div class="groups__form-btn">
                  <button type="button" class="btn btn-blue h-44 js-open-dropdown" @click="addTag">Добавить <span class="desk-only">тег</span></button>
                </div>
              </div>
              <div v-if="studyGroup?.tags" class="groups__chips">
                <div class="groups__chip" v-for="tag in selectedTagsChips" :key="tag.title">
                  <p class="groups__chip-text">{{ tag.title }}</p>
                  <button class="groups__chip-btn" @click="closeTagChip(tag.title)"></button>
                </div>
              </div>
            </div>
          </div>
          <div class="groups__form-button">
            <button class="btn btn-blue h-44" @click="updateInfo" v-if="!isLoading">Сохранить</button>
            <button class="btn btn-blue h-44" disabled v-else>Сохраняем...</button>
          </div>
          <div class="groups__form-link" v-if="profile.user.permission.student_groups.delete">
            <a href="javascript:void(0)" @click="deleteConfirmation">Удалить группу</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import moment from 'moment';
import _ from 'lodash';

import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader2 from '@/components/AppLoader/2.vue';
import InfiniteLoading from 'vue-infinite-loading';

import vSelect from 'vue-select';
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';

export default {
  name: 'StudyGroupsEdit',

  components: {
    AppLoader,
    vSelect,
    AppLoader2,
    InfiniteLoading,
  },

  data() {
    return {
      group: {
        title: '',
        code: '',
        course_id: '',
        theory_start_at: '',
        theory_finish_at: '',
        practice_start_at: '',
        final_examination_at: '',
        qualifying_examination_at: '',
        teachers: [],
        instructors: [],
        tags: [],
      },
      teacher: null,
      instructor: null,
      tag: null,
      resetHover: false,
      selectedTeachersChips: [],
      selectedInstructorsChips: [],
      selectedTagsChips: [],
      isLoading: false,
      isLoadingSpinner: false,
      isDropdownVisible: false,
    }
  },

  computed: {
    ...mapGetters('studyGroups', {
      studyGroup: 'item',
      loading: 'loading',
      tags: 'tags',
    }),
    ...mapGetters({
      courses: 'courses',
      coursesLoading: 'coursesLoading',
      coursesMeta: 'coursesMeta',
      clients: 'filterClients',
      filterClientsMeta: 'filterClientsMeta',
      instructors: 'assignmentClientInstructors',
    }),
    ...mapGetters(['profile']),

    filteredTeachers() {
      return this.clients.filter(teacher => {
        return !this.selectedTeachersChips.some(selectedTeacher => selectedTeacher.client_id === teacher.client_id);
      });
    },
    filteredInsctructors() {
      return this.instructors.instructors.filter(instructor => {
        return !this.selectedInstructorsChips.some(selectedInstructor => selectedInstructor.instructor_id === instructor.instructor_id);
      });
    },
    filteredTags() {
      return this.tags.filter(tag => 
        tag.title.toLowerCase().includes(this.tag.toLowerCase())
      );
    }
  },

  async mounted() {
    let id = this.$route.params.id;
    this.clearStudyGroup();
    await this.getStudyGroup(id);
    this.group.title = this.studyGroup.title;
    if (this.studyGroup.code) {
      this.group.code = this.studyGroup.code;
    }
    if (this.studyGroup.course_id) {
      this.group.course_id = this.studyGroup.course_id;
    }
    if (this.studyGroup.theory_start_at) {
      this.group.theory_start_at = moment(this.studyGroup.theory_start_at).format('DD.MM.YYYY');
    }
    if (this.studyGroup.theory_finish_at) {
      this.group.theory_finish_at = moment(this.studyGroup.theory_finish_at).format('DD.MM.YYYY');
    }
    if (this.studyGroup.practice_start_at) {
      this.group.practice_start_at = moment(this.studyGroup.practice_start_at).format('DD.MM.YYYY');
    }
    if (this.studyGroup.final_examination_at) {
      this.group.final_examination_at = moment(this.studyGroup.final_examination_at).format('DD.MM.YYYY');
    }
    if (this.studyGroup.qualifying_examination_at) {
      this.group.qualifying_examination_at = moment(this.studyGroup.qualifying_examination_at).format('DD.MM.YYYY');
    }
    if (this.studyGroup.teachers.length > 0) {
      this.selectedTeachersChips.push(...this.studyGroup.teachers);
      this.studyGroup.teachers.forEach((teacher) => {
        let id = teacher.client_id;
        this.group.teachers.push(id);
      });
    }
    if (this.studyGroup.instructors.length > 0) {
      this.selectedInstructorsChips.push(...this.studyGroup.instructors);
      this.studyGroup.instructors.forEach((instructor) => {
        let id = instructor.instructor_id;
        this.group.instructors.push(id);
      });
    }
    if (this.studyGroup.tags.length > 0) {
      this.selectedTagsChips = this.studyGroup.tags.map(tag => ({ title: tag.title }));
      this.studyGroup.tags.forEach((tag) => {
        this.group.tags.push(tag.title);
      });
    }

    let that = this;

    new AirDatepicker('#calendar-modal-theory-start', {
      onSelect({date, formattedDate}) {
        console.log(date);
        that.group.theory_start_at = formattedDate
      }
    });

    new AirDatepicker('#calendar-modal-theory-finish', {
      onSelect({date, formattedDate}) {
        console.log(date);
        that.group.theory_finish_at = formattedDate
      }
    });

    new AirDatepicker('#calendar-modal-practice-start', {
      onSelect({date, formattedDate}) {
        console.log(date);
        that.group.practice_start_at = formattedDate
      }
    });

    new AirDatepicker('#calendar-modal-final-examination', {
      onSelect({date, formattedDate}) {
        console.log(date);
        that.group.final_examination_at = formattedDate
      }
    });

    new AirDatepicker('#calendar-modal-qualifying-examination', {
      onSelect({date, formattedDate}) {
        console.log(date);
        that.group.qualifying_examination_at = formattedDate
      }
    });

    this.clearCourses();
    this.getCourses();
    this.clearFilterClients();
    this.getFilterClients();
    this.changePageFilterClients(1);
    this.changeFiltersFilterClientsSearch(null);
    this.clearСlientInstructors();
    this.getСlientInstructors({loading: true});
    this.getTags();

    document.addEventListener('click', this.hideDropdownList);
  },

  destroyed() {
    this.clearStudyGroup();
    this.clearCourses();
    this.clearСlientInstructors();
    this.clearFilterClients();

    document.removeEventListener('click', this.hideDropdownList);
  },

  methods: {
    ...mapActions('studyGroups', {
      getStudyGroup: 'getItem',
      clearStudyGroup: 'clearItem',
      updateGroupInfo: 'edit',
      getTags: 'getGroupTags',
      updateTagsFilters: 'updateTagsFilters',
      deleteStudyGroup: 'remove'
    }),
    ...mapActions([
      'getCourses',
      'getFilterClients',
      'clearFilterClients',
      'changePageFilterClients',
      'changeFiltersFilterClientsSearch',
      'changeFiltersSearchСlientInstructors',
      'getСlientInstructors',
      'clearСlientInstructors',
      'clearCourses',
    ]),
    hideDropdownList(event) {
      if (this.$refs.dropdownList?.contains(event.target)) {
        return;
      } else {
        this.isDropdownVisible = false;
      }
    },
    async onInput() {
      this.isLoadingSpinner = true;
      this.updateTagsFilters({ query: this.tag }); 
      await this.getTags()
      .catch((error) => {
        this.errorHandlerForResponse(error);
      })
      .finally(() => {
        this.isLoadingSpinner = false;
      });
      if (this.tags.length > 0) {
        this.isDropdownVisible = true;
      }
    },
    searchClient(search, loading) {
      this.clearFilterClients();
      this.changePageFilterClients(1);
      this.changeFiltersFilterClientsSearch(search);
      loading(true);
      this.searchClientsAction(search, loading, this);
    },
    searchClientsAction: _.debounce((search, loading, vm) => {
      vm.getFilterClients(false).then(() => {
        loading(false);
      })
    }, 1000),
    infiniteHandlerClient($state) {
      if (this.clients.length < this.filterClientsMeta.entries.total) {
        this.getFilterClients(false)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    searchInstructor(search, loading) {
      this.changeFiltersSearchСlientInstructors(search);
      loading(true);
      this.searchInstructorAction(search, loading, this);
    },
    searchInstructorAction: _.debounce((search, loading, vm) => {
      const payload = {
        loading: false
      }
      vm.getСlientInstructors(payload).then(() => {
        loading(false);
      })
    }, 1000),
    infiniteHandlerInstructor($state) {
      if (this.instructors.instructors.length < this.instructors.meta.entries.total) {
        const payload = {
          loading: false,
        }

        this.getСlientInstructors(payload)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    infiniteHandlerCourse($state) {
      if (this.courses.length < this.coursesMeta.total) {
        this.getCourses(false)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    addTeacher(value) {
      let index = this.group.teachers.indexOf(value.client_id);
      if (index == -1) {
        this.group.teachers.push(value.client_id);
        this.teacher = null;
        this.selectedTeachersChips.push(value);
      }
    },
    closeTeacherChip(id) {
      let index = this.group.teachers.indexOf(id);
      if (index !== -1) {
        this.group.teachers.splice(index, 1)
        const indexChips = this.selectedTeachersChips.findIndex(t => t.client_id === id);
        if (indexChips !== -1) {
          this.selectedTeachersChips.splice(indexChips, 1)
        }
      }
    },
    addInstructor(value) {
      let index = this.group.instructors.indexOf(value.instructor_id);
      if (index == -1) {
        this.group.instructors.push(value.instructor_id);
        this.instructor = null;
        this.selectedInstructorsChips.push(value);
      }
    },
    closeInstructorChip(id) {
      let index = this.group.instructors.indexOf(id);
      if (index !== -1) {
        this.group.instructors.splice(index, 1)
        const indexChips = this.selectedInstructorsChips.findIndex(i => i.instructor_id === id);
        if (indexChips !== -1) {
          this.selectedInstructorsChips.splice(indexChips, 1)
        }
      }
    },
    selectTag(tag) {
      const index = this.selectedTagsChips.findIndex(selectedTag => selectedTag.title === tag.title);

      if (index === -1) {
        this.group.tags.push(tag.title);
        this.selectedTagsChips.push(tag);
        this.tag = null; 
      }

      this.isDropdownVisible = false; 
    },
    addTag() {
      if (this.tag && this.tag.trim()) {
        const newTag = { title: this.tag.trim() };
        const index = this.selectedTagsChips.findIndex(tag => tag.title === newTag.title);
        
        if (index === -1) {
          this.group.tags.push(newTag.title);
          this.selectedTagsChips.push(newTag); 
          this.tag = null; 
        }
      }
    },
    closeTagChip(title) {
      const index = this.group.tags.indexOf(title); 
      if (index !== -1) {
        this.group.tags.splice(index, 1);
        const indexChips = this.selectedTagsChips.findIndex(t => t.title === title); 
        if (indexChips !== -1) {
          this.selectedTagsChips.splice(indexChips, 1);
        }
      }
    },
    async updateInfo() {
      this.isLoading = true;
      let payload = {
        group: this.group,
        groupId: this.$route.params.id,
      }
      await this.updateGroupInfo(payload)
      .catch((error) => {
        this.errorHandlerForResponse(error);
      })
      .finally(() => {
        this.isLoading = false;
        this.$router.push(`/study-groups/${payload.groupId}/info?tab=info`)
      });
    },
    deleteConfirmation() {
      this.$modal.show('dialog', {
        text: 'Вы действительно хотите удалить группу?',
        buttons: [
          {
            title: 'Да',
            handler: () => {
              this.delStudyGroup();
              this.$modal.hide('dialog');
            },
          },
          {
            title: 'Нет',
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
        ],
      });
    },
    delStudyGroup() {

      let payload = {
        group: this.group,
        groupId: this.$route.params.id,
      }

      this.deleteStudyGroup(payload)
        .then(() => {
          this.$toast.open({
            message: 'Группа удалена',
            type: 'success'
          });
        })
        .catch((error) => {
          this.errorHandlerForResponse(error);
        })
        .finally(() => 
          this.$router.push('/study-groups'),
        )
    },
    moment,
    errorHandlerForResponse
  }
}
</script>

<style lang="scss">
.groups__form-dropdown {
  max-height: 140px;
  overflow: hidden;
  overflow-y: scroll;
  display: flex !important;
}

.groups__form-dropdown-item {
  cursor: pointer;
}

.groups__form-field--tag .form-control {
  position: relative;
}

.groups__form-field--tag .loader-2-wrapper {
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.groups__form-link {
  text-align: center;
}

.groups__form-link a {
  color: red;
  text-decoration: underline;
  font-size: 14px;
}

.groups__form-link a:hover {
  color: red;
}
</style>