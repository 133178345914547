import instance from '../instance';
import settings from '../settings';

export default {
  state: {
    orders: null,
    pageOrders: 1,
    order: null,
    meta: null,
    payments: null,
    tariffs: [],
    tariffsForOptions: [],
    orderOptions: null,
    orderTariffsMeta: null,
    tariffsPage: 1,
    loading: {
      orders: true,
      order: true,
      payments: true,
    },
    filters: {
      page: 1,
      page_size: 10,
      query: '',
      client_id: null,
      date_from: '',
      date_to: '',
      status: null,
    },
    tracksFilter: {
      page: 1,
      query: '',
      page_size: 10,
      course_id: null,
    },
    tariffsTracksFilter: {
      page: 1,
      query: '',
      page_size: 10,
      course_id: null,
    },
    tariffsOptionsFilter: {
      page: 1,
      query: '',
      page_size: 10,
      option_id: null,
    },
    tracksTotal: 0,
    tariffsTotal: 0,
    tariffsOptionsTotal: 0,
    tracks: [],
  },

  getters: {
    orders: state => state.orders,
    order: state => state.order,
    ordersMeta: state => state.meta,
    ordersLoading: state => state.loading,
    orderLoading: state => state.loading,
    payments: state => state.payments,
    tariffsOrder: state => state.tariffs,
    tariffsForOptions: state => state.tariffsForOptions,
    orderTariffsMeta: state => state.orderTariffsMeta,
    orderOptions: state => state.orderOptions,
    orderTracks: state => state.tracks,
    tracksFilter: state => state.tracksFilter,
    tariffsTracksFilter: state => state.tariffsTracksFilter,
    tariffsOptionsFilter: state => state.tariffsOptionsFilter,
    tracksTotal: state => state.tracksTotal,
    tariffsTotal: state => state.tariffsTotal,
    tariffsOptionsTotal: state => state.tariffsOptionsTotal,
    pageOrders: state => state.filters.page,
    filters: state => state.filters,
    ordersFiltersApplied: state =>
      Boolean(state.filters.client_id)
      || Boolean(state.filters.status)
      || Boolean(state.filters.date_from)
      || Boolean(state.filters.date_to)
      || Boolean(state.filters.query)
  },

  actions: {
    async getOrders({ commit, state }, loading) {
      if (loading) {
        commit('START_GET_ORDERS_LOADING');
      }
      const requestId = 'orders';

      const params = Object.fromEntries(Object.entries(state.filters).filter(([key, value]) => key && value));
      const orders = await instance.get(`${settings.API_URL}/billing/orders`, { requestId, params });
      commit('SET_ORDERS', orders.data);
      commit('FINISH_GET_ORDERS_LOADING');
    },
    async getOrder({ commit }, id) {
      const order = await instance.get(`${settings.API_URL}/billing/orders/${id}`);
      commit('SET_ORDER', order.data);
    },
    changePageOrders({ commit }, page) {
      commit('CHANGE_PAGE_ORDERS', page);
    },
    changeFiltersSearchOrders({ commit }, search) {
      commit('CHANGE_FILTERS_SEARCH_ORDERS', search);
    },
    changeFiltersForOrdersClient({commit}, id) {
      commit('CHANGE_FILTERS_FOR_ORDERS_CLIENT', id);
    },
    changeFiltersForOrdersStatus({commit}, value) {
      commit('CHANGE_FILTERS_FOR_ORDERS_STATUS', value);
    },
    changeFiltersDateForOrders({commit}, date) {
      let from = null;
      let to = null;
      if (date && date.from) {
        from = date.from;
      }
      if (date && date.to) {
        to = date.to;
      }
      commit('CHANGE_FILTERS_DATE_FROM_FOR_ORDERS', from);
      commit('CHANGE_FILTERS_DATE_TO_FOR_ORDERS', to);
    },
    resetFiltersOrders({ commit }) {
      commit('CHANGE_PAGE_ORDERS', 1);

      commit('CHANGE_FILTERS_FOR_ORDERS_CLIENT', null);

      commit('CHANGE_FILTERS_FOR_ORDERS_STATUS', null);

      commit('CHANGE_FILTERS_DATE_FROM_FOR_ORDERS', null);

      commit('CHANGE_FILTERS_DATE_TO_FOR_ORDERS', null);

      commit('CHANGE_FILTERS_SEARCH_ORDERS', null);
    },
    async getSchoolPayments({ commit }, id) {
      const payments = await instance.get(`${settings.API_URL}/billing/orders/${id}/payments`);
      commit('SET_PAYMENTS', payments.data);
    },
    async getOrderTracksTariffs({ commit, state }, id) {
      commit('SET_TARIFFS_FOR_TRACKS', id)
      const requestId = 'orderTariffs';
      const params = Object.fromEntries(Object.entries(state.tariffsTracksFilter).filter(([key, value]) => key && value));
      const tariffs = await instance.get(`${settings.API_URL}/billing/tariffs`, { requestId, params });
      commit('SET_TARIFFS', tariffs.data);
    },
    async getOrderOptionsTariffs({ commit, state }, id) {
      commit('SET_TARIFFS_FOR_OPTIONS', id)
      const requestId = 'orderTariffs';
      const params = Object.fromEntries(Object.entries(state.tariffsOptionsFilter).filter(([key, value]) => key && value));
      const tariffs = await instance.get(`${settings.API_URL}/billing/tariffs`, { requestId, params });
      commit('SET_TARIFFS_OPTIONS', tariffs.data);
    },
    async getOrderTracks({ commit, state }, id) {
      commit('SET_TRACKS_FOR_COURSE', id)
      const requestId = 'orderTracks';
      const params = Object.fromEntries(Object.entries(state.tracksFilter).filter(([key, value]) => key && value));
      const tracks = await instance.get(`${settings.API_URL}/tracks`, { requestId, params });
      commit('SET_TRACKS', tracks.data);
    },
    async getOptions({ commit }) {
      const orderOptions = await instance.get(`${settings.API_URL}/billing/options`);
      commit('SET_OPTIONS', orderOptions.data);
    },
    clearFilterTracks({ commit }) {
      commit('CLEAR_TRACKS_FILTER');
    },
    clearOrderTracks({ commit }) {
      commit('CLEAR_ORDER_TRACKS');
    },
    setFilterQueryTracks({ commit }, query) {
      commit('SET_QUERY_TRACKS', query);
    },
    clearFilterTariffs({ commit }) {
      commit('CLEAR_TARIFFS_FILTER');
    },
    clearTrackTariffs({ commit }) {
      commit('CLEAR_TRACK_TARIFFS');
    },
    clearOptionsTariffs({ commit }) {
      commit('CLEAR_OPTIONS_TARIFFS');
    },
    setFilterQueryTariffs({ commit }, query) {
      commit('SET_QUERY_TARIFFS', query);
    },
    clearOrder({ commit }) {
      commit('CLEAR_ORDER');
    },
    /* eslint-disable no-empty-pattern */
    async addPayment({ }, payload) {
      await instance.post(`${settings.API_URL}/billing/orders/${payload.id}/payments`, {payment: payload.payment});
    },
    async deletePayment({ commit }, payload) {
      const order = await instance.delete(`${settings.API_URL}/billing/orders/${payload.idOrder}/payments/${payload.idPayment}`);
      if (order.data.order) {
        commit('SET_ORDER', order.data)
      }
    },
    async addOrder({ }, payload) {
      await instance.post(`${settings.API_URL}/billing/orders/`, payload);
    },
    async changeOrder({ }, orderPayload) {
      await instance.put(`${settings.API_URL}/billing/orders/${orderPayload.id}`, {order: orderPayload.order});
    },
    async deleteOrder({ }, orderPayload) {
      await instance.delete(`${settings.API_URL}/billing/orders/${orderPayload.id}`, {order: orderPayload.order});
    },
  },

  mutations: {
    SET_QUERY_TARIFFS(state, query) {
      state.tariffsOptionsFilter.query = query;
      state.tariffsTracksFilter.query = query;
    },
    SET_QUERY_TRACKS(state, query) {
      state.tracksFilter.query = query;
    },
    CHANGE_PAGE_ORDERS(state, page) {
      state.filters.page = page;
    },
    CHANGE_FILTERS_SEARCH_ORDERS(state, search) {
      state.filters.query = search;
    },
    CHANGE_FILTERS_FOR_ORDERS_CLIENT(state, id) {
      state.filters.client_id = id;
    },
    CHANGE_FILTERS_FOR_ORDERS_STATUS(state, value) {
      state.filters.status = value;
    },
    CHANGE_FILTERS_DATE_FROM_FOR_ORDERS(state, date) {
      state.filters.date_from = date;
    },
    CHANGE_FILTERS_DATE_TO_FOR_ORDERS(state, date) {
      state.filters.date_to = date;
    },
    SET_ORDERS(state, orders) {
      state.meta = orders.meta;
      state.orders = orders.orders;
    },
    SET_ORDER(state, order) {
      state.order = order.order;
      state.loading.order = false;
    },
    START_GET_ORDERS_LOADING(state) {
      state.loading.orders = true;
    },
    FINISH_GET_ORDERS_LOADING(state) {
      state.loading.orders = false;
    },
    SET_PAYMENTS(state, payments) {
      state.payments = payments.payments;
      state.loading.payments = false;
    },
    SET_TRACKS(state, tracks) {
      state.tracksFilter.page = state.tracksFilter.page+1;
      state.tracksTotal = tracks.total;
      state.tracks.push(...tracks.data);
    },
    CLEAR_ORDER_TRACKS(state) {
      state.tracks = []
      state.tracksFilter = {
        page: 1,
        query: '',
        page_size: 10,
        course_id: null,
      },
      state.tracksTotal = 0;
    },
    CLEAR_TRACKS_FILTER(state) {
      state.tracks = [];
      state.tracksFilter = {
        page: 1,
        query: '',
        page_size: 10,
        course_id: null,
      },
      state.tracksTotal = 0;
    },
    CLEAR_TARIFFS_FILTER(state) {
      state.tariffs = [];
      state.tariffsOptionsFilter.page = 1;
      state.tariffsOptionsFilter.query = '';
      state.tariffsTracksFilter.page = 1;
      state.tariffsTracksFilter.query = '';
      state.tariffsTotal = 0;
    },
    SET_TARIFFS(state, tariffs) {
      state.tariffsTracksFilter.page = state.tariffsTracksFilter.page+1;
      state.tariffs.push(...tariffs.tariffs);
      state.tariffsTotal = tariffs.meta.entries.total;
    },
    SET_TARIFFS_OPTIONS(state, tariffs) {
      state.tariffsOptionsFilter.page = state.tariffsOptionsFilter.page+1;
      state.tariffsForOptions.push(...tariffs.tariffs);
      state.tariffsOptionsTotal = tariffs.meta.entries.total;
    },
    SET_TARIFFS_FOR_TRACKS(state, id) {
      state.tariffsTracksFilter.course_id = id;
    },
    SET_TRACKS_FOR_COURSE(state, id) {
      state.tracksFilter.course_id = id;
    },
    SET_TARIFFS_FOR_OPTIONS(state, id) {
      state.tariffsOptionsFilter.option_id = id;
    },
    SET_OPTIONS(state, orderOptions) {
      state.orderOptions = orderOptions.data;
    },
    CLEAR_ORDER(state) {
      state.order = null;
      state.payments = null;
      state.loading.order = true;
      state.loading.payments = true;
    },
    CLEAR_TRACK_TARIFFS(state) {
      state.tariffs = [];
    },
    CLEAR_OPTIONS_TARIFFS(state) {
      state.tariffsForOptions = [];
    },
  },
};