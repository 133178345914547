<template>
  <div :class="{ 'account-sidebar--close': isSidebarClose }" class="account-sidebar">
    <div v-if="profile" class="account-menu account-menu--school">
      <router-link
        v-if="profile.user.permission.instructor.view"
        :to="{ name: 'Instructors', params: { page: 1 } }"
        class="account-menu-item d-flex"
        @click.native="sidebarMenuClick"
      >
        <div class="account-menu-item-icon">
          <i class="my-clients"></i>
        </div>
        <div class="account-menu-item-title">
          Инструкторы
        </div>
      </router-link>

      <router-link
        v-if="profile.user.permission.client.view"
        :to="{ name: 'Clients', params: { page: 1 } }"
        class="account-menu-item d-flex"
        @click.native="sidebarMenuClick"
      >
        <div class="account-menu-item-icon">
          <i class="user"></i>
        </div>
        <div class="account-menu-item-title">
          Клиенты
        </div>
      </router-link>

      <router-link
        v-if="profile.user.permission.student_groups.view"
        :to="{ name: 'StudyGroups', params: { page: 1 } }"
        class="account-menu-item d-flex"
        @click.native="sidebarMenuClick"
      >
        <div class="account-menu-item-icon">
          <i class="clients"></i>
        </div>
        <div class="account-menu-item-title">
          Группы обучения
        </div>
      </router-link>

      <router-link
        v-if="profile.user.permission.analytics.view"
        :to="{ name: 'Analytics', query: { registered_from: dayOfWeekFrom(-6), registered_to: dayOfWeekTo(7) }, params: { page: 1 } }"
        exact-path
        class="account-menu-item d-flex"
        @click.native="sidebarMenuClick"
      >
        <div class="account-menu-item-icon">
          <i class="analitics"></i>
        </div>
        <div class="account-menu-item-title">
          Аналитика
        </div>
      </router-link>

      <router-link
        v-if="profile.user.permission.reports.view"
        :to="{ name: 'Reports'}"
        class="account-menu-item d-flex"
        @click.native="sidebarMenuClick"
      >
        <div class="account-menu-item-icon">
          <i class="reports"></i>
        </div>
        <div class="account-menu-item-title">
          Отчеты о занятиях
        </div>
      </router-link>

      <router-link
      v-if="profile.user.permission.orders.view"
        :to="{ name: 'Orders'}"
        class="account-menu-item d-flex"
        @click.native="sidebarMenuClick"
      >
        <div class="account-menu-item-icon">
          <i class="orders"></i>
        </div>
        <div class="account-menu-item-title">
          Заказы
        </div>
      </router-link>

      <router-link
        v-if="profile.user.permission.user.view"
        :to="{ name: 'Administrators', params: { page: 1 } }"
        class="account-menu-item d-flex"
        @click.native="sidebarMenuClick"
      >
        <div class="account-menu-item-icon">
          <i class="administrators"></i>
        </div>
        <div class="account-menu-item-title">
          Администраторы
        </div>
      </router-link>

      <router-link
        v-if="profile.user.permission.settlements.view"
        :to="{ name: 'Transactions', query: { status: 'received', created_at_from: dayOfWeekFrom(1), created_at_to: dayOfWeekTo(7) }, params: { page: 1 } }"
        exact-path
        class="account-menu-item d-flex"
        @click.native="sidebarMenuClick"
      >
        <div class="account-menu-item-icon">
          <i class="card"></i>
        </div>
        <div class="account-menu-item-title">
          Взаиморасчеты
        </div>
      </router-link>

      <router-link
        v-if="profile.user.permission.activity.view"
        :to="{ name: 'Activity', query: { date_from: dayOfWeekFrom(1), date_to: dayOfWeekTo(7) }, params: { page: 1 } }"
        exact-path
        class="account-menu-item d-flex"
        @click.native="sidebarMenuClick"
      >
        <div class="account-menu-item-icon">
          <i class="views"></i>
        </div>
        <div class="account-menu-item-title">
          Журнал действий
        </div>
      </router-link>

      <router-link
        v-if="profile.user.permission.service_exams.view || profile.user.permission.service_hub.view"
        :to="{ name: 'Services'}"
        class="account-menu-item d-flex"
        @click.native="sidebarMenuClick"
      >
        <div class="account-menu-item-icon">
          <i class="services"></i>
        </div>
        <div class="account-menu-item-title">
          Сервисы
        </div>
      </router-link>

      <router-link
        v-if="profile.user.permission.school_requisites.view || profile.user.permission.school_settings.view"
        @click.native="sidebarMenuClick"
        to="/organization-settings" class="account-menu-item d-flex">
        <div class="account-menu-item-icon">
          <i class="settings"></i>
        </div>
        <div class="account-menu-item-title">
          Настройки организации
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import moment from 'moment';

export default {
  name: 'AppSidebar',

  computed: {
    ...mapGetters(['profile', 'isSidebarClose']),
  },

  methods: {
    ...mapActions(['toggleSidebarClose']),
    dayOfWeekFrom(from) {
      return moment().day(from).format('DD-MM-YYYY');
    },
    dayOfWeekTo(to) {
      return moment().day(to).format('DD-MM-YYYY');
    },
    sidebarMenuClick() {
      if (window.innerWidth < 990) {
        this.toggleSidebarClose();
      }
    }
  },
}
</script>

<style>
  .account-menu-item.active .clients {
    background-image: url(~@/assets/img/clients-active.svg) !important;
  }
  .account-menu-item.active .analitics {
    background-image: url(~@/assets/img/analitics-active.svg) !important;
  }
  .account-menu-item .orders {
    background-image: url(~@/assets/img/orders.svg) !important;
    width: 18px;
    height: 18px;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    opacity: 0.6;
  }
  .account-menu-item.active .orders {
    background-image: url(~@/assets/img/orders-active.svg) !important;
    opacity: 1;
  }
  .account-sidebar {
    transition: .3s ease-in-out;
  }
  .account-menu-item-title {
    opacity: 1;
    transition: opacity .3s .3s;
  }
  .account-sidebar--close .account-menu-item-title {
    display: block !important;
    opacity: 0;
    transition: .1s;
  }
  .account-sidebar--close+.account-content{
    width: calc(100% - 95px) !important;
  }
  @media screen and (max-width: 990px) {
    .account-sidebar--close+.account-content{
      width: 100% !important;
    }
    .account-sidebar {
      position: absolute;
      z-index: 1000;
      margin-top: -25px;
      display: none !important;
      width: 266px !important;
      min-width: 266px !important;
    }
    .account-sidebar--close {
      display: block !important;
    }
    .account-sidebar--close .account-menu-item-title {
      display: block !important;
      opacity: 1 !important;
    }
  }
</style>
