<template>
  <div class="account-content">
    <GoBack />
    <div class="school-cabinet_wrapper wrapper-for-spinner" v-if="!loading.item && !loading.payments"  :class="{ opacity: isLoadingPage }">
      <div class="account-subtitle pt-0 mb-4">Редактировать заказ №{{ item.order_id }}</div>
      <div class="school-cabinet school-cabinet--settings-order">
        <div class="school-cabinet_white-box school-cabinet_white-box--settings-order pb-md-0">
          <div class="settings_filters settings_filters--settings-order ml-md-2 mr-md-2">
            <div class="settings_filters-content d-flex justify-content-between justify-content-md-start">
              <div class="settings_filters-item" :class="{active: tab === 1}">
                <a href="javascript:void(0)" @click="tab = 1">Заказ</a>
              </div>
              <div class="settings_filters-item" :class="{active: tab === 2}">
                <a href="javascript:void(0)" @click="tab = 2">Платежи</a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="tab == 1">
          <div class="school-cabinet_card school-cabinet_card--settings-order">
            <div class="school-cabinet_card-top d-flex align-items-start flex-wrap">
              <div class="school-cabinet_card-info school-cabinet_card-info--id">
                <div class="school-cabinet_card-info-item mr-0 pr-0">
                  <div class="school-cabinet_card-info-title">
                    <strong>ID</strong>
                  </div>
                  <div class="school-cabinet_card-info-text mb-2 mb-md-0">
                    <span class="light">{{ item.order_id }}</span>
                  </div>
                </div>
              </div>
              <div 
                class="school-cabinet_card-content school-cabinet_card-content--orders d-flex align-items-center mb-3 mb-xl-0">
                <div class="school-cabinet_card-text">
                  <div class="school-cabinet_card-name">
                    {{ item.client.name.first }} {{ item.client.name.last }} {{ item.client.name.middle }}
                  </div>
                  <div class="school-cabinet_card-facts" v-if="item.client.email && item.client.phone">
                    {{ item.client.email }}, {{ item.client.phone }}
                  </div>
                </div>
              </div>
              <div class="school-cabinet_card-info school-cabinet_card-info--status">
                <div class="school-cabinet_card-info-item mr-0 pr-0 pr-md-1">
                  <div class="school-cabinet_card-info-title">
                    <strong>{{ getOrdersStatus(item.status) }}</strong>
                  </div>
                  <div class="school-cabinet_card-info-text mb-2 mb-md-0" v-if="item.created_at">
                    <span class="light">{{ formatDate(item.created_at) }}</span>
                  </div>
                </div>
              </div>
              <div class="school-cabinet_card-info school-cabinet_card-info--cat" v-if="item && item.items && item.items.length > 0">
                <div class="school-cabinet_card-info-item mr-0 pr-0 pr-md-1">
                  <div class="school-cabinet_card-info-title">
                    <strong>{{ item.items[0].product.course_track.title }}</strong>
                  </div>
                  <div class="school-cabinet_card-info-text mb-2 mb-md-0">
                    <span class="light">{{ item.items[0].tariff.name }}</span>
                  </div>
                </div>
              </div>
              <div class="school-cabinet_card-info school-cabinet_card-info--payment">
                <div class="school-cabinet_card-info-item mr-0 pr-0 pr-md-1">
                  <div class="school-cabinet_card-info-title">
                    <strong>{{ item.total }} ₽ / {{ item.paid }} ₽ / {{ item.due }} ₽</strong>
                  </div>
                  <div class="school-cabinet_card-info-text mb-2 mb-md-0">
                    <span class="light">Сумма / Оплачено / Долг</span>
                  </div>
                </div>
              </div>
              <div class="school-cabinet_card-info school-cabinet_card-info--payment" v-if="item && item.promocode && item.promocode.code">
                <div class="school-cabinet_card-info-item mr-0 pr-0 pr-md-1">
                  <div class="school-cabinet_card-info-title">
                    <strong>Промокод</strong>
                  </div>
                  <div class="school-cabinet_card-info-text mb-2 mb-md-0">
                    <span class="light">{{ item.promocode.code }}</span>
                  </div>
                </div>
              </div>
              <div class="school-cabinet_card-info school-cabinet_card-info--payment" v-if="item && item.items[1] && item.items[1].product && item.items[1].product.billing_option && item.items[1].product.billing_option.name">
                <div class="school-cabinet_card-info-item mr-0 pr-0 pr-md-1">
                  <div class="school-cabinet_card-info-title">
                    <strong>Опция</strong>
                  </div>
                  <div class="school-cabinet_card-info-text mb-2 mb-md-0">
                    <span class="light">{{ item.items[1].product.billing_option.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="school-cabinet_white-box school-cabinet_white-box--settings-order pb-md-0" v-if="profile.user.permission.orders.edit">
            <div class="account-subtitle pt-0 mb-3">Редактировать</div>
            <div class="school-cabinet_info pt-1">
              <div class="school-cabinet_info-form school-cabinet_info-form--full">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="school-cabinet_info-field school-cabinet_info-field--small">
                    <v-select
                      :options="tracks"
                      v-if="tracks"
                      @input="selectTrack"
                      @search="searchTracks"
                      :clearable="false"
                      :filterable="false"
                      :searchable="true"
                      placeholder="Поток"
                      :get-option-label='option => option.title ? `${option.title}` : null'
                      :get-option-key='option => option.track_id'
                    >
                      <template #open-indicator="{ attributes }">
                        <span class="form-control--arrow" v-bind="attributes"></span>
                      </template>
                      <template slot="no-options">
                        Потоков не найдено
                      </template>
                      <template v-slot:option="option">
                        {{ option.title }} 
                      </template>
                      <template #spinner="{ loading }">
                        <AppLoader2 :mini="true" v-if="loading" />
                      </template>
                      <template #list-footer>
                        <infinite-loading @infinite="infiniteHandlerTracks">
                          <div slot="spinner">
                            <AppLoader2 :mini="true" />
                          </div>
                          <div slot="no-more"></div>
                          <div slot="no-results"></div>
                        </infinite-loading>
                      </template>
                    </v-select>
                  </div>
                  <template v-if="!profile.user.permission.orders.update">
                    <div class="school-cabinet_info-field school-cabinet_info-field--small" v-if="order && order.items[0] && order.items[0].product && order.items[0].product.id > 0">
                      <v-select
                        :options="tariffs"
                        v-if="tariffs"
                        @search="searchTariff"
                        @input="selectTariff"
                        :clearable="false"
                        :filterable="false"
                        :searchable="true"
                        placeholder="Тариф"
                        :get-option-label='option => option.name ? `${option.name}` : null'
                        :get-option-key='option => option.tariff_id'
                      >
                        <template #open-indicator="{ attributes }">
                          <span class="form-control--arrow" v-bind="attributes"></span>
                        </template>
                        <template slot="no-options">
                          Тарифов не найдено
                        </template>
                        <template v-slot:option="option">
                          {{ option.name }} 
                        </template>
                        <template #list-footer>
                          <infinite-loading @infinite="infiniteHandlerTariffs">
                            <div slot="spinner">
                              <AppLoader2 :mini="true" />
                            </div>
                            <div slot="no-more"></div>
                            <div slot="no-results"></div>
                          </infinite-loading>
                        </template>
                      </v-select>
                    </div>
                    <div class="school-cabinet_info-field school-cabinet_info-field--small" v-else>
                      <v-select
                        v-if="tariffs"
                        placeholder="Тариф"
                        :disabled="disabledFilters"
                      >
                        <template #open-indicator="{ attributes }">
                          <span class="form-control--arrow" v-bind="attributes"></span>
                        </template>
                      </v-select>
                    </div>
                    <div class="school-cabinet_info-field school-cabinet_info-field--small">
                      <input type="text" class="form-control form-control--bordered" 
                        placeholder="Промокод, если есть" 
                        v-model="order.promocode.code"
                      >
                    </div>
                    <div class="school-cabinet_info-field school-cabinet_info-field--small">
                      <div class="custom-checkbox">
                        <input type="checkbox" name="paymentType" id="installmentPayment" v-model="order.in_installments">
                        <label for="installmentPayment" class="d-flex">В рассрочку</label>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div v-if="!profile.user.permission.orders.update" class="school-cabinet_info-form school-cabinet_info-form--full">
                <div class="school-cabinet_info-field school-cabinet_info-field--small">
                  <div class="option__button" @click="isShowOptions = true">
                    <div class="option__button-icon"></div>
                    <div class="option__button-text">Добавить опцию, если есть</div>
                  </div>
                </div>
                <div class="d-flex justify-content-between flex-wrap" v-if="isShowOptions">
                  <div class="school-cabinet_info-field school-cabinet_info-field--small">
                    <v-select
                      :options="orderOptions"
                      v-if="orderOptions"
                      @input="selectOption"
                      :clearable="false"
                      :searchable="false"
                      placeholder="Опция"
                      :get-option-label='option => option.name ? `${option.name}` : null'
                      :get-option-key='option => option.option_id'
                    >
                      <template #open-indicator="{ attributes }">
                        <span class="form-control--arrow" v-bind="attributes"></span>
                      </template>
                      <template slot="no-options">
                        Опций не найдено
                      </template>
                      <template v-slot:option="option">
                        {{ option.name }} 
                      </template>
                    </v-select>
                  </div>
                  <div class="school-cabinet_info-field school-cabinet_info-field--small" v-if="order && order.items[1] && order.items[1].product && order.items[1].product.id > 0">
                    <v-select
                      :options="tariffsForOptions"
                      v-if="tariffsForOptions"
                      @input="selectTariffForOption"
                      :clearable="false"
                      :searchable="true"
                      @search="searchTariffOptions"
                      placeholder="Тариф"
                      :get-option-label='option => option.name ? `${option.name}` : null'
                      :get-option-key='option => option.tariff_id'
                    >
                      <template #open-indicator="{ attributes }">
                        <span class="form-control--arrow" v-bind="attributes"></span>
                      </template>
                      <template slot="no-options">
                        Тарифов не найдено
                      </template>
                      <template v-slot:option="option">
                        {{ option.name }} 
                      </template>
                      <template #list-footer>
                        <infinite-loading @infinite="infiniteHandlerTariffsOptions">
                          <div slot="spinner">
                            <AppLoader2 :mini="true" />
                          </div>
                          <div slot="no-more"></div>
                          <div slot="no-results"></div>
                        </infinite-loading>
                      </template>
                    </v-select>
                  </div>
                  <div class="school-cabinet_info-field school-cabinet_info-field--small" v-else>
                    <v-select
                      v-if="tariffs"
                      placeholder="Тариф"
                      :disabled="disabledFilters"
                    >
                      <template #open-indicator="{ attributes }">
                        <span class="form-control--arrow" v-bind="attributes"></span>
                      </template>
                    </v-select>
                  </div>
                  <div class="school-cabinet_info-field school-cabinet_info-field--small mb-0"></div>
                </div>
              </div>
              <div class="school-cabinet_info-field school-cabinet_info-field--small text-center mt-2 mb-3 mb-md-0">
                <button class="btn btn-blue btn-long" @click="save" v-if="!isSubmitting">Сохранить</button>
                <button class="btn btn-blue btn-long" v-else>Сохраняем...</button>
              </div>
              <div class="school-cabinet_info-field school-cabinet_info-field--small text-center mt-4 mb-3 mb-md-0">
                <AppLoader2
                  v-if="isLoaderDeletingOrder"
                  :mini="true"
                />
                <button v-else class="btn btn--white btn-long" @click="deleteConfirmation">Удалить</button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="tab == 2">
          <div class="school-cabinet_white-box">
            <div class="school-cabinet_wrapper">
              <div class="school-cabinet_wrapper-content d-flex flex-column">
                <div class="mb-4 mb-md-5" v-if="payments && payments.length > 0">
                  <div class="school-cabinet_card school-cabinet_card--order-payment">
                    <div class="school-cabinet_card-top d-flex align-items-start flex-wrap">
                      <div class="school-cabinet_card-info school-cabinet_card-info--1">
                        <div class="school-cabinet_card-info-item  ml-md-2 mr-md-2">
                          <div class="school-cabinet_card-info-title">
                            <strong>Статус</strong>
                          </div>
                        </div>
                      </div>
                      <div class="school-cabinet_card-info school-cabinet_card-info--2">
                        <div class="school-cabinet_card-info-item  ml-md-2 mr-md-2">
                          <div class="school-cabinet_card-info-title">
                            <strong>Сумма</strong>
                          </div>
                        </div>
                      </div>
                      <div class="school-cabinet_card-info school-cabinet_card-info--3">
                        <div class="school-cabinet_card-info-item  ml-md-2 mr-md-2">
                          <div class="school-cabinet_card-info-title">
                            <strong>Шлюз</strong>
                          </div>
                        </div>
                      </div>
                      <div class="school-cabinet_card-info school-cabinet_card-info--4">
                        <div class="school-cabinet_card-info-item  ml-md-2 mr-md-2">
                          <div class="school-cabinet_card-info-title">
                            <strong>Дата оплаты</strong>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="school-cabinet_card-info school-cabinet_card-info--5">
                        <div class="school-cabinet_card-info-item  ml-md-2 mr-md-2">
                          <div class="school-cabinet_card-info-title">
                            <strong>Дата платежа по графику</strong>
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                  <div 
                    class="school-cabinet_card school-cabinet_card--order-payment"
                    v-for="payment in payments"
                    :key="payment.payment_id"
                    :payment="payment"
                  >
                    <div class="school-cabinet_card-top d-flex align-items-start flex-wrap">
                      <div class="school-cabinet_card-info school-cabinet_card-info--1">
                        <div class="school-cabinet_card-info-item  ml-md-2 mr-md-2">
                          <div class="school-cabinet_card-info-title">
                            {{ getPaymentsStatus(payment.status) }}
                          </div>
                        </div>
                      </div>
                      <div class="school-cabinet_card-info school-cabinet_card-info--2">
                        <div class="school-cabinet_card-info-item  ml-md-2 mr-md-2">
                          <div class="school-cabinet_card-info-title">
                            <strong>{{ payment.amount }} ₽</strong>
                          </div>
                        </div>
                      </div>
                      <div class="school-cabinet_card-info school-cabinet_card-info--3">
                        <div class="school-cabinet_card-info-item  ml-md-2 mr-md-2">
                          <div class="school-cabinet_card-info-title">
                            {{ getPaymentsGateway(payment.gateway.gateway) }}
                          </div>
                        </div>
                      </div>
                      <div class="school-cabinet_card-info school-cabinet_card-info--4">
                        <div class="school-cabinet_card-info-item  ml-md-2 mr-md-2">
                          <div class="school-cabinet_card-info-title">
                            {{ formatDate(payment.created_at) }}
                          </div>
                        </div>
                      </div>
                      <!-- <div class="school-cabinet_card-info school-cabinet_card-info--5">
                        <div class="school-cabinet_card-info-item  ml-md-2 mr-md-2">
                          <input type="text" class="form-control form-control--calendar-icon" placeholder="29.09.2023" readonly>
                        </div>
                      </div> -->
                      <div class="d-flex align-items-center">
                        <!-- <div class="school-cabinet_card-file ml-4"></div> -->
                        <template v-if="payment && payment.gateway && payment.gateway.gateway == 'manual' && profile.user.permission.orders.payments_delete">
                          <AppLoader2
                            v-if="isLoaderDeletingPayment"
                            :mini="true"
                          />
                          <div v-else class="school-cabinet_card-delete school-cabinet_card-delete--grey ml-4" @click="deletePaymentConfirmation(payment.payment_id)"></div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="installment-block mb-4 mb-md-5" v-if="item.in_installments == true">
                  <div class="installment-block__title ml-md-2">
                    Рассрочка
                  </div>
                  <div class="school-cabinet_card school-cabinet_card--order-payment">
                    <div class="school-cabinet_card-top d-flex align-items-start flex-wrap">
                      <div class="school-cabinet_card-info school-cabinet_card-info--1">
                        <div class="school-cabinet_card-info-item  ml-md-2 mr-md-2">
                          <div class="school-cabinet_card-info-title">
                            <strong>Статус</strong>
                          </div>
                        </div>
                      </div>
                      <div class="school-cabinet_card-info school-cabinet_card-info--2">
                        <div class="school-cabinet_card-info-item  ml-md-2 mr-md-2">
                          <div class="school-cabinet_card-info-title">
                            <strong>Сумма</strong>
                          </div>
                        </div>
                      </div>
                      <div class="school-cabinet_card-info school-cabinet_card-info--3">
                        <div class="school-cabinet_card-info-item  ml-md-2 mr-md-2">
                          <div class="school-cabinet_card-info-title">
                            <strong>Срок оплаты</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div 
                    class="school-cabinet_card school-cabinet_card--order-payment"
                    v-for="(payment, index) in item.installment_items"
                    :key="index"
                  >
                    <div class="school-cabinet_card-top d-flex align-items-start flex-wrap">
                      <div class="school-cabinet_card-info school-cabinet_card-info--1">
                        <div class="school-cabinet_card-info-item  ml-md-2 mr-md-2">
                          <div class="school-cabinet_card-info-title">
                            {{ getInstallmentStatus(payment.status) }}
                          </div>
                        </div>
                      </div>
                      <div class="school-cabinet_card-info school-cabinet_card-info--2">
                        <div class="school-cabinet_card-info-item  ml-md-2 mr-md-2">
                          <div class="school-cabinet_card-info-title">
                            <strong>{{ payment.amount }} ₽</strong>
                          </div>
                        </div>
                      </div>
                      <div class="school-cabinet_card-info school-cabinet_card-info--3">
                        <div class="school-cabinet_card-info-item  ml-md-2 mr-md-2">
                          <div class="school-cabinet_card-info-title">
                            {{ formatDateForInstallment(payment.due_date) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="school-cabinet_info-field  school-cabinet_info-field--order-payment school-cabinet_info-field--small text-center">
              <button class="btn btn-blue btn-long">Сохранить</button>
            </div> -->
            <div class="account-subtitle pt-0 mb-3" v-if="profile.user.permission.orders.edit && !profile.user.permission.orders.update">Добавить платеж</div>
            <div class="school-cabinet_info pl-0 pt-1" v-if="profile.user.permission.orders.edit && !profile.user.permission.orders.update">
              <div class="school-cabinet_info-form school-cabinet_info-form--full">
                <div class="d-flex flex-wrap">
                  <!-- <div class="school-cabinet_info-field school-cabinet_info-field--small">
                    <v-select
                      :clearable="false"
                      :searchable="false"
                      placeholder="Шлюз"
                    >
                      <template #open-indicator="{ attributes }">
                        <span class="form-control--arrow" v-bind="attributes"></span>
                      </template>
                    </v-select>
                  </div> -->
                  <div class="school-cabinet_info-field school-cabinet_info-field--small">
                    <input type="number" class="form-control form-control--bordered" placeholder="Сумма, ₽" v-model="payment.amount">
                  </div>
                  <div class="school-cabinet_info-field school-cabinet_info-field--small ml-md-2">
                    <button class="btn btn-blue btn-full" @click="addPaymentClick" v-if="!isLoading">Добавить</button>
                    <button class="btn btn-blue btn-full" disabled v-else>Добавляем...</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AppLoader v-else />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import errorHandlerForResponse from '@/helpers/errorHandlerForResponse.js';
import _ from 'lodash';

import GoBack from '@/components/GoBack.vue';
import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader2 from '@/components/AppLoader/2.vue';
import InfiniteLoading from 'vue-infinite-loading';
import vSelect from 'vue-select';
export default {
  name: 'OrdersEdit',

  components: {
    GoBack,
    AppLoader,
    vSelect,
    InfiniteLoading,
    AppLoader2,
  },

  data() {
    return {
      code: false,
      tab: 1,
      isLoading: false,
      isLoadingPage: false,
      isSubmitting: false,
      isShowOptions: false,
      disabledFilters: true,
      isLoaderDeletingOrder: false,
      isLoaderDeletingPayment: false,
      payment: {
        amount: '',
      },
      id: 0,
      order: {
        client: {
          client_id: '',
        },
        items: [
          {
            product: {
              type: 'course_track',
              id: '',
            },
            tariff_id: '',
          },
        ],
        in_installments: false,
        promocode: {
          code: '',
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      item: 'order',
      payments: 'payments',
      profile: 'profile',
      loading: 'orderLoading',
      clients: 'filterClients',
      filterClientsMeta: 'filterClientsMeta',
      pageTracksAmount: 'pageTracksAmount',
      tracks: 'orderTracks',
      tracksTotal: 'tracksTotal',
      tariffsTotal: 'tariffsTotal',
      orderTariffsMeta: 'orderTariffsMeta',
      tariffs: 'tariffsOrder',
      orderOptions: 'orderOptions',
      tariffsForOptions: 'tariffsForOptions',
    })
  },

  async created() {
    let id = this.$route.params.id;
    await this.getOrder(id);
    await this.getSchoolPayments(id);

    if (this.profile.user.permission.orders.edit) {
      await this.clearFilterClients();
      await this.clearOrderTracks();
      await this.changeFiltersFilterClientsSearch(null);
      await this.getFilterClients();
      await this.getOrderTracks(this.item.items[0].product.course_track.course_id);
      await this.getOptions();

      this.order.client.client_id = this.item.client.name.full;
      this.order.promocode.code = this.item.promocode?.code;
      this.order.items[0].tariff_id = this.item.items[0].tariff.tariff_id;
      this.order.in_installments = this.item.in_installments;
      this.id = this.$route.params.id;
    }
  },

  destroyed() {
    this.clearOrder();
    this.clearOrderTracks();
  },

  methods: {
    ...mapActions([
      'getOrder',
      'getSchoolPayments',
      'addPayment',
      'clearOrder',
      'clearFilterClients',
      'changePageFilterClients',
      'changeFiltersFilterClientsSearch',
      'getFilterClients',
      'clearFilterTariffs',
      'getOrderTracks',
      'clearFilterTracks',
      'clearOrderTracks',
      'setFilterQueryTracks',
      'setFilterQueryTariffs',
      'clearOptionsTariffs',
      'getOrderOptionsTariffs',
      'clearTrackTariffs',
      'getOrderTracksTariffs',
      'changeOrder',
      'getOptions',
      'deleteOrder',
      'deletePayment',
    ]),
    getUserName(option) {
      if (option) {
        if (option.name.middle) {
          return `${option.name.first} ${option.name.last} ${option.name?.middle}`;
        } else {
          return `${option.name.first} ${option.name.last}`;
        }
      }
      return null;
    },
    selectClient(client) {
      this.order.client.client_id = client.client_id;
    },
    infiniteHandlerTariffsOptions($state) {
      if (this.tariffs.length < this.tariffsTotal) {
        this.getOrderOptionsTariffs(false)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    selectTrack(track) {
      this.clearFilterTariffs();
      this.clearTrackTariffs();
      this.getOrderTracksTariffs(track.course?.course_id);
      this.order.items[0].product.id = track.track_id;
    },
    selectTariff(tariff) {
      this.order.items[0].tariff_id = tariff.tariff_id;
    },
    selectOption(option) {
      this.clearFilterTariffs();
      this.clearOptionsTariffs();
      this.getOrderOptionsTariffs(option.option_id);
      if (this.order.items.length > 1) {
        this.order.items.splice(1, 1);
      }
      this.order.items.push({
        product: {
          type: 'billing_option',
          id: option.option_id,
        },
        tariff_id: null,
      })
    },
    selectTariffForOption(tariff) {
      this.order.items[1].tariff_id = tariff.tariff_id;
    },
    searchClient(search, loading) {
      this.clearFilterClients();
      this.changePageFilterClients(1);
      this.changeFiltersFilterClientsSearch(search);
      loading(true);
      this.searchClientsAction(search, loading, this);
    },
    searchClientsAction: _.debounce((search, loading, vm) => {
      vm.getFilterClients(false).then(() => {
        loading(false);
      })
    }, 1000),
    infiniteHandler($state) {
      if (this.clients.length < this.filterClientsMeta.entries.total) {
        this.getFilterClients(false)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    searchTracks(search, loading) {
      this.clearFilterTracks();
      this.setFilterQueryTracks(search);
      loading(true);
      this.searchTracksAction(search, loading, this);
    },
    searchTracksAction: _.debounce((search, loading, vm) => {
      const payload = {
        id: vm.item.items[0].product.course_track.course_id,
      }
      vm.getOrderTracks(payload.id).then(() => {
        loading(false);
      })
    }, 1000),
    infiniteHandlerTracks($state) {
      if (this.tracks.length < this.tracksTotal) {
        this.getOrderTracks(this.item.items[0].product.course_track.course_id)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    searchTariff(search, loading) {
      this.clearFilterTariffs();
      this.setFilterQueryTariffs(search);
      loading(true);
      this.searchTariffsAction(search, loading, this);
    },
    searchTariffsAction: _.debounce((search, loading, vm) => {
      vm.getOrderTracksTariffs(false).then(() => {
        loading(false);
      })
    }, 1000),
    infiniteHandlerTariffs($state) {
      if (this.tariffs.length < this.tariffsTotal) {
        this.getOrderTracksTariffs(false)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    searchTariffOptions(search, loading) {
      this.clearFilterTariffs();
      this.setFilterQueryTariffs(search);
      loading(true);
      this.searchTariffsAction(search, loading, this);
    },
    searchTariffsOptionsAction: _.debounce((search, loading, vm) => {
      vm.getOrderOptionsTariffs(false).then(() => {
        loading(false);
      })
    }, 1000),
    addPaymentClick() {
      this.isLoading = true;
      let payload = {
        payment: this.payment,
        id: this.$route.params.id,
      }
      this.addPayment(payload).then(() => {
        this.isLoading = false;
        this.getSchoolPayments(payload.id);
        this.payment.amount = '';
        let id = this.$route.params.id;
        this.getOrder(id);
      }).catch((e) => {
				this.isLoading = false;
				this.errorHandlerForResponse(e);
			})
    },
    getOrdersStatus(code) {
      if (code === 'new') {
        return 'Ожидает оплаты'
      }
      if (code === 'pending') {
        return 'Ожидает оплаты'
      }
      if (code === 'installment') {
        return 'Оплачен в рассрочку'
      }
      if (code === 'paid') {
        return 'Полностью оплачен'
      }
    },
    getPaymentsStatus(code) {
      if (code === 'new') {
        return 'Ожидает оплаты'
      }
      if (code === 'pending') {
        return 'Ожидает оплаты'
      }
      if (code === 'done') {
        return 'Ожидает оплаты'
      }
      if (code === 'paid') {
        return 'Оплачен'
      }
      if (code === 'failed') {
        return 'Ошибка'
      }
    },
    getInstallmentStatus(code) {
      if (code === 'due') {
        return 'Должен оплатить'
      }
      if (code === 'overdue') {
        return 'Оплата просрочена'
      }
      if (code === 'paid') {
        return 'Оплачено'
      }
      if (code === 'canceled') {
        return 'Отменен'
      }
    },
    getPaymentsGateway(code) {
      if (code === 'tinkoff_protechnologies') {
        return 'Тинькоф'
      }
      if (code === 'tinkoff_megapolis') {
        return 'Тинькоф'
      }
      if (code === 'manual') {
        return 'Вручную'
      }
    },
    formatDate(date) {
			return moment(date).format('DD.MM.YYYY, HH:mm');
		},
    formatDateForInstallment(date) {
			return moment(date).format('DD.MM.YYYY');
		},
    async save() {
      this.isSubmitting = true;
      let payload = {
        order: this.order,
        id: this.id,
      }
      await this.changeOrder(payload);
      this.isSubmitting = false;
      this.$router.push('/orders')
    },
    deleteConfirmation() {
      this.$modal.show('dialog', {
        text: 'Вы действительно хотите удалить заказ?',
        buttons: [
          {
            title: 'Да',
            handler: () => {
              this.delOrder();
              this.$modal.hide('dialog');
            },
          },
          {
            title: 'Нет',
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
        ],
      });
    },
    delOrder() {
      this.isLoaderDeletingOrder = true;

      let payload = {
        order: this.order,
        id: this.id,
      }

      this.deleteOrder(payload)
        .then(() => {
          this.$toast.open({
            message: 'Заказ удален',
            type: 'success'
          });
        })
        .catch((error) => {
          this.errorHandlerForResponse(error);
        })
        .finally(() => 
          this.isLoaderDeletingOrder = false,
          this.$router.push('/orders'),
        )
    },
    deletePaymentConfirmation(id) {
      this.$modal.show('dialog', {
        text: 'Вы действительно хотите удалить платёж?',
        buttons: [
          {
            title: 'Да',
            handler: () => {
              this.delPayment(id);
              this.$modal.hide('dialog');
            },
          },
          {
            title: 'Нет',
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
        ],
      });
    },
    delPayment(id) {
      this.isLoaderDeletingPayment = true;

      let payload = {
        idPayment: id,
        idOrder: this.$route.params.id
      }

      this.deletePayment(payload)
        .then(() => {
          this.$toast.open({
            message: 'Платёж удален',
            type: 'success'
          });
          this.getSchoolPayments(payload.idOrder);
        })
        .catch((error) => {
          this.errorHandlerForResponse(error);
        })
        .finally(() => 
          this.isLoaderDeletingPayment = false,
          this.$router.push(`/orders/${this.$route.params.id}/edit`),
        )
    },
    errorHandlerForResponse,
  }
}
</script>

<style>
.school-cabinet_card--order-payment .school-cabinet_card-info {
  height: auto !important;
}
.school-cabinet_card--order-payment .school-cabinet_card-info--1 {
  width: 170px !important;
}
.installment-block__title {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  margin-bottom: 27px;
}
.school-cabinet--settings-order {
  overflow: visible !important;
}
</style>